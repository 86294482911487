import React, { Component } from "react";

import HomeValue from "./HomeValue";
import BubbleCompareTestimonial from "./BubbleCompareTestimonial";
import FactBubbles from "./FactBubbles";
import BackpageBoxes from "./BackpageBoxes";
import JohnsonsQuadrants from "views/Pma/PmaEditor/CustomAgentPages/JohnsonsQuadrants";
import JohnsonsLogoQuadrants from "views/Pma/PmaEditor/CustomAgentPages/JohnsonsLogoQuadrants";
import JohnsonsEducationFund from "views/Pma/PmaEditor/CustomAgentPages/JohnsonsEducationFund";
import JohnsonsQuadrantsTwo from "views/Pma/PmaEditor/CustomAgentPages/JohnsonsQuadrantsTwo";
import BrettNote from "views/Pma/PmaEditor/CustomAgentPages/BrettNote";

class BubbleCompareFirstLeft extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      testimonials,
      demoAccountAreaName,
      MQCityDetails,
      coverPageDetails,
      quarterlyAreaTable,
      quarterlyCityTable,
      bubbleBoxes,
      isDemo,
      propertyPhotoDetailsDTO,
    } = this.props;

    let isBuellton =
      currentArea.mlsAreaID === "SBA-40F" ||
      currentArea.mlsAreaID === "SBA-40G";
    const { listgenUserID } = agentData;
    let isJohnsons = listgenUserID === "100988";
    let isBrett = listgenUserID === "100227";

    return (
      <div className="bubble-compare-first-left">
        {/*<FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <HOAddress
              hoData={hoData}
              version={version}
              agentData={agentData}
              currentArea={currentArea}
              printVersion={printVersion}
              mailInfoClass="mail-info-container-print"
              demoAccountAreaName={demoAccountAreaName}
              // typeOfVersion="qv4"
              coverPageDetails={coverPageDetails}
            />
          )}
*/}
        {/*{testimonials && (
            <BubbleCompareTestimonial
              agentData={agentData}
              testimonials={testimonials}
            />
          )}*/}
        {isJohnsons ? (
          <JohnsonsLogoQuadrants />
        ) : isBrett ? (
          <BrettNote />
        ) : bubbleBoxes ? (
          <BackpageBoxes
            currentArea={currentArea}
            agentData={agentData}
            bubbleBoxes={bubbleBoxes}
            mlsAreas={currentArea.mlsAreas}
            printVersion={printVersion}
            isDemo={isDemo}
            hoData={hoData}
          />
        ) : (
          <div>Please upload information</div>
        )}

        {/*<FactBubbles
            currentArea={currentArea}
            hoData={hoData}
            bubbleDataDetails={
              isBuellton
                ? quarterlyCityTable.bubbleDataDetails
                : quarterlyAreaTable.bubbleDataDetails
            }
            quarterlyAreaTable={
              isBuellton ? quarterlyCityTable : quarterlyAreaTable
            }
          />*/}
      </div>
    );
  }
}

export default BubbleCompareFirstLeft;
