import React, { Component } from "react";
import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";
import QVFourAgentLogoHeader from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourAgentLogoHeader";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";

import YETwoShortenedMarketTable from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoShortenedMarketTable";
import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";
import GNQRCode from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/GNQRCode";

class YETwoSecondRight extends Component {
  sortByStatus = (propList, getExtendedTable, alwaysExtendedTable) => {
    const { mlsAreaID } = this.props.currentArea;
    const { listgenUserID } = this.props.agentData;

    const {
      activePhotoDetailsList,
      contingentPhotoDetailsList,
      pendingPhotoDetailsList,
      preSoldPhotoDetailsList,
      recentSoldPhotoDetailsList,
      agentFilteredPhotoDetailsList,
    } = this.props.quarterlyAreaTable;

    let isLoriB = listgenUserID === "102548";
    let sold = recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList : [];
    let pend = pendingPhotoDetailsList ? pendingPhotoDetailsList : [];
    let cont = contingentPhotoDetailsList ? contingentPhotoDetailsList : [];
    let active =
      mlsAreaID === "SCL-237"
        ? activePhotoDetailsList.slice(0, 6)
        : mlsAreaID === "157"
        ? activePhotoDetailsList.slice(0, 7)
        : activePhotoDetailsList
        ? activePhotoDetailsList
        : [];
    let laterSold = preSoldPhotoDetailsList ? preSoldPhotoDetailsList : [];
    let agentsProps = agentFilteredPhotoDetailsList
      ? agentFilteredPhotoDetailsList
      : [];
    if (listgenUserID === "105642") {
      let filteredForAnna = laterSold
        .slice()
        .filter(
          (property) =>
            (property.streetNumber === "259" &&
              property.streetName === "Belvue") ||
            (property.streetNumber === "108" && property.streetName === "Bond")
        );

      laterSold = filteredForAnna;
    }

    // if (listgenUserID === "102288") {
    //   let avenidaPrincipalProp = agentsProps.filter(
    //     (property) =>
    //       property.streetName === "Avenida Principal" &&
    //       property.streetNumber === "24725"
    //   );
    //
    //   laterSold.unshift(avenidaPrincipalProp[0]);
    //   agentsProps = agentsProps.filter(
    //     (property) =>
    //       property.streetName !== "Avenida Principal" &&
    //       property.streetNumber !== "24725"
    //   );
    // }

    let last45DaysSum =
      (agentFilteredPhotoDetailsList ? agentFilteredPhotoDetailsList : 0) +
      (activePhotoDetailsList ? activePhotoDetailsList.length : 0) +
      (contingentPhotoDetailsList ? contingentPhotoDetailsList.length : 0) +
      (pendingPhotoDetailsList ? pendingPhotoDetailsList.length : 0) +
      (recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList.length : 0);

    let remainderPreSold = 12 - last45DaysSum;

    const sortByStatusThenDate = (data) => {
      let sortedSolds = data
        .filter((x) => x.status === "Sold")
        .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));

      let newConstructed = [
        ...data.filter((x) => x.status === "Active"),
        ...data.filter(
          (x) => x.status === "Pending" || x.status === "Contingent"
        ),
        ...sortedSolds,
      ];
      let uniqueProps = newConstructed.filter(
        (obj1, i, arr) =>
          arr.findIndex(
            (obj2) =>
              obj2.streetNumber === obj1.streetNumber &&
              obj2.streetName === obj1.streetName &&
              obj2.status === obj1.status
          ) === i
      );

      return uniqueProps;
    };

    const sortByStatusThenPropType = (data) => {
      let singleFamHomes = data
        .filter(
          (x) => x.status === "Sold" && x.propertyType === "Single Family Home"
        )
        .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));

      let townhomeSolds = data
        .filter((x) => x.status === "Sold" && x.propertyType === "Townhouse")
        .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));

      let sortedSolds = [...singleFamHomes, ...townhomeSolds];

      let newConstructed = [
        ...data.filter((x) => x.status === "Active"),
        ...data.filter(
          (x) => x.status === "Pending" || x.status === "Contingent"
        ),
        ...sortedSolds,
      ];
      let uniqueProps = newConstructed.filter(
        (obj1, i, arr) =>
          arr.findIndex(
            (obj2) =>
              obj2.streetNumber === obj1.streetNumber &&
              obj2.streetName === obj1.streetName &&
              obj2.status === obj1.status
          ) === i
      );

      return uniqueProps;
    };
    let umiParkComingSoon = [];
    if (listgenUserID === "105016") {
      let property = {
        address: "-",
        bedBaths: "4/2",
        brokerageName: "Keller Williams Realty-Silicon Valley",
        coListingAgentFirstName: null,
        coListingAgentLastName: null,
        coListingAgents: null,
        daysOnMarket: "N/A",
        guestHouseBathsAndBeds: null,
        isAgentFiltered: true,
        isOffMarketSale: "0",
        listDate: "2024-10-16T12:01:22",
        listPrice: "-",
        listingAgentLicenseID: "70010218",
        listingAgentName: "Umi Park",
        lotSizeSquareFt: "7500",
        originalListPrice: "1498000",
        photoURL:
          "https://optimized-property-photos.s3.amazonaws.com/Optimized-ML81983893-81983893.jpg",
        propertyType: "Single Family Home",
        saleDate: "-",
        salePrice: "-",
        sellingAgentLicenseID: "02185903",
        sellingAgentName: "Umi Park",
        squareFeet: "1741",
        status: "Active",
        streetDirPrefix: "-",
        streetName: "Nola",
        streetNumber: "2238",
        streetSuffix: "Dr",
        unitNumber: "-",
        view: "Neighborhood",
      };
      umiParkComingSoon = [property];
    }

    let trueSort1 = [
      ...umiParkComingSoon,
      ...agentsProps,
      ...active,
      ...pend,
      ...cont,
      ...sold,

      ...laterSold.slice(
        0,
        mlsAreaID === "SCL-1V" ? remainderPreSold + 2 : remainderPreSold
      ),
    ];

    let trueSort = sortByStatusThenDate(trueSort1);

    if (
      (last45DaysSum < 12 && listgenUserID !== "101268") ||
      alwaysExtendedTable
    ) {
      if (getExtendedTable) {
        return false;
      } else if (mlsAreaID === "SMA-392") {
        let propTypeSort = sortByStatusThenPropType(trueSort1);
        return propTypeSort;
      } else {
        return trueSort;
      }
    } else {
      let today = new Date();
      let date45DaysAgo = new Date(
        today.setDate(today.getDate() - 45)
      ).toISOString();

      let last45DayDates = trueSort.filter(
        (x) =>
          (x.saleDate !== "-" && x.saleDate > date45DaysAgo) ||
          x.status == "Active" ||
          x.status === "Contingent" ||
          x.status === "Pending"
      );

      let remainderExtended = 18 - last45DayDates.length;

      let extendedTrueSort1 = [
        ...agentsProps,
        ...active,
        ...pend,
        ...cont,
        ...sold,
        ...laterSold.slice(0, remainderExtended),
      ];
      let extendedTrueSort = sortByStatusThenDate(extendedTrueSort1);

      if (getExtendedTable) {
        return true;
      } else {
        return extendedTrueSort.slice(0, 12);
      }
    }
    // return propList.slice(0, 19);
  };
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,

      demoAccountHOName,

      photoTableData,
      quarterlyAreaTable,
      quarterlyCityTable,
      MQCityDetails,
      isDemo,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    const { currentSoldListings } = quarterlyAreaTable;
    let useExtendedTable = false;
    if (listgenUserID === "x105886") {
      useExtendedTable = true;
    }

    let sorted =
      currentSoldListings && currentSoldListings
        ? this.sortByStatus(currentSoldListings, false, useExtendedTable)
        : [];
    let isJohnsonTeam = listgenUserID === "100988";
    // let useExtendedTable =
    //   currentSoldListings && currentSoldListings
    //     ? this.sortByStatus(currentSoldListings, true)
    //     : [];
    // let isBellaterra = mlsAreaID === "SCL-16M";
    // if (isBellaterra) {
    //   useExtendedTable = false;
    // }

    let useCity = false;
    if (mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G") {
      useCity = true;
    }

    if (mlsAreaID.includes("xSBA")) {
      return (
        <div
          className={`${
            version === "year-end-2022" ? "ye2" : "qv4"
          }-second-right`}
        >
          <QVFourAgentLogoHeader
            agentData={agentData}
            currentArea={currentArea}
          />
          {hoData &&
            hoData.changeDetails &&
            Object.keys(hoData.changeDetails).length > 1 && (
              <QVFourPS
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                mlsAreas={mlsAreas}
                version={version}
                printVersion={printVersion}
                mailingNum="qv4"
                demoAccountHOName={demoAccountHOName}
                isDemo={isDemo}
              />
            )}
          <GNQRCode hoData={hoData} agentData={agentData} smallerQR={true} />
          <QVFourPhotoSection
            currentArea={currentArea}
            agentData={agentData}
            printVersion={printVersion}
            version={version}
            hoData={hoData}
            currentSoldListings={currentSoldListings}
          />
          <QVFourPresentedByFooter
            agentData={agentData}
            currentArea={currentArea}
            currentSoldListings={currentSoldListings}
            printVersion={printVersion}
          />
        </div>
      );
    } else {
      return (
        <div
          className={`${
            version === "year-end-2022" ? "ye2" : "qv4"
          }-second-right`}
        >
          <QVFourAgentLogoHeader
            agentData={agentData}
            currentArea={currentArea}
          />
          {hoData &&
            hoData.changeDetails &&
            Object.keys(hoData.changeDetails).length > 1 && (
              <QVFourPS
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                mlsAreas={mlsAreas}
                version={version}
                printVersion={printVersion}
                mailingNum="qv4"
                demoAccountHOName={demoAccountHOName}
                isDemo={isDemo}
              />
            )}
          {!isJohnsonTeam && (
            <GNQRCode
              hoData={hoData}
              agentData={agentData}
              currentArea={currentArea}
            />
          )}

          <YETwoShortenedMarketTable
            quarterlyCityTable={quarterlyCityTable}
            currentArea={currentArea}
            quarterlyAreaTable={
              useCity ? quarterlyCityTable : quarterlyAreaTable
            }
            useCity="false"
            hoData={hoData}
            agentData={agentData}
            MQCityDetails={MQCityDetails}
            lastMidQuarterlyPrintDate={
              quarterlyAreaTable.lastMidQuarterlyPrintDate
            }
            //printDate="Nov 1, 2021"
            printVersion={printVersion}
            currentSoldListings={currentSoldListings}
            sorted={sorted}
            useExtendedTable={useExtendedTable}
          />
          {!useExtendedTable && (
            <MQThreeAvailableHomes
              currentArea={currentArea}
              quarterlyAreaTable={quarterlyAreaTable}
              quarterlyCityTable={quarterlyCityTable}
              MQCityDetails={MQCityDetails}
              hoData={hoData}
              agentData={agentData}
              useCity={useCity}
              altVersion="may-2024"
              printVersion={printVersion}
            />
          )}

          <QVFourPresentedByFooter
            agentData={agentData}
            currentArea={currentArea}
            currentSoldListings={currentSoldListings}
            printVersion={printVersion}
          />
        </div>
      );
    }
  }
}

export default YETwoSecondRight;
