import React, { Component } from "react";

import QuickToMarketTrends from "./QuickToMarketTrends";
import QVTwoTopSales from "./QVTwoTopSales";

import JohnsonsQuadrants from "views/Pma/PmaEditor/CustomAgentPages/JohnsonsLogoQuadrants";
import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd/YETwo/YETwoFirstPage/YETwoFirstLeft";
import BackpageBoxes from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/BackpageBoxes";

class QVTwoFirstLeft extends Component {
  render() {
    const {
      auth,
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      qArea,
      coverPageDetails,
      propertyPhotoDetailsDTO,
      bubbleBoxes,
      mlsAreas,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    let isJohnsons = listgenUserID === "100988";
    console.log("qv2 first left props", this.props);
    return (
      <div className="qv2-first-left">
        {/*<FirstLeftHeader
          auth={auth}
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <HOAddress
          hoData={hoData}
          version={version}
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
          mailInfoClass="qv4-mail-info-container-print"
          typeOfVersion="qv4"
          coverPageDetails={coverPageDetails}
        />*/}
        {isJohnsons ? (
          <JohnsonsQuadrants />
        ) : (
          <BackpageBoxes
            currentArea={currentArea}
            agentData={agentData}
            bubbleBoxes={bubbleBoxes}
            mlsAreas={currentArea.mlsAreas}
            printVersion={printVersion}
          />
        )}
      </div>
    );
  }
}

export default QVTwoFirstLeft;
