import React, { useEffect } from "react";
import { connect } from "react-redux";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { MailProDialog } from "../MailProDialog/MailProDialog";
import { MailProDialogHeader } from "../MailProDialog/MailProDialogHeader";
import { MailProDialogBody } from "../MailProDialog/MailProDialogBody";
import MailProButton from "../UI/MailProButton";
import MailProTextarea from "../UI/MailProTextarea";
import { SubmitHelpRequestAPI } from "services/others";
import helpUtils from "views/Help/HelpUtils";
import {
  capitalizeFirstLetterForEveryword,
  showErrorNotification,
  showSuccessNotification,
} from "common/helpers";
import classNames from "classnames";
import { Button } from "@material-ui/core";

const MailProSupport = ({
  classes,
  accessedFromAreaID,
  accessedFromAreaName,
  children,
  agentData,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);
  const [helpMessage, setMessageData] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [isSubmitted, setSubmitted] = React.useState(false);

  const { listgenUserFirstName } = agentData;

  const handleDataUpdate = (value) => {
    setMessageData(value);
  };

  const handleResubmit = () => {
    setSubmitted(false);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setSubmitted(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    const dataFn = () => agentData;
    const { listgenUserID, name, email, phone } = helpUtils.getHelpObject(
      null,
      dataFn
    );
    const { cityName, areaNames } = helpUtils.getAgentAreasandCityName(
      accessedFromAreaID,
      dataFn
    );
    SubmitHelpRequestAPI(
      listgenUserID,
      name,
      email,
      phone,
      helpUtils.subjectBuilder(dataFn),
      helpMessage,
      "request",
      capitalizeFirstLetterForEveryword(cityName.toLowerCase()),
      capitalizeFirstLetterForEveryword(areaNames.toLowerCase())
    )
      .then((data) => {
        switch (data) {
          case true:
            showSuccessNotification(rest, "Request submitted.");
            setSubmitted(true);
            setMessageData("");
            break;
          default:
            showErrorNotification(
              rest,
              "Something went wrong. Please try after some time."
            );
            break;
        }
        setLoading(false);
      })
      .catch((err) => {
        showErrorNotification(rest, "Unable to process request.");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        {children ? (
          children
        ) : (
          <MailProButton
            variant="filled"
            label={"Support"}
            customStyle={{
              fontSize: "12px",
              fontWeight: 600,
              backgroundColor: "white",
              color: "black",
            }}
            onClick={() => {}}
            title="Need any Help? Click here to open Help"
          />
        )}
      </div>
      {open && (
        <MailProDialog
          open={open}
          classes={{
            paper: classes.mailproSupportDialogPaper,
          }}
        >
          <MailProDialogHeader>
            <div className={classes.mailproSupportHeader}>
              <div className={classes.mailproSupportHeaderFullName}>
                {!isSubmitted ? `ListGen SUPPORT` : `SUPPORT REQUEST RECEIVED`}
              </div>
            </div>
          </MailProDialogHeader>
          <MailProDialogBody>
            {!isSubmitted ? (
              <div>
                <div className={classes.mailproSupportTitle}>
                  <div className={classes.mailproSupportAgentName}>
                    Greetings, {listgenUserFirstName}
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    Please type in your SUPPORT issue and/or questions.
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    One of our team members will respond to you as quickly as
                    possible.
                  </div>
                </div>
                <div>
                  <MailProTextarea
                    rows={8}
                    fullWidth={true}
                    onChange={(event) => handleDataUpdate(event.target.value)}
                    value={helpMessage}
                    placeholder={"Type here..."}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.mailproSupportTitle}>
                  <div> Thank you for your support request.</div>
                  <div>
                    One of our team members will respond to you as quickly as
                    possible.
                  </div>
                </div>
              </div>
            )}
            {!isSubmitted ? (
              <div className={classes.footer}>
                <div>
                  <MailProButton
                    key={"mp_new_ticket_cancel"}
                    variant="outlined"
                    label={"CANCEL"}
                    customStyle={{ fontSize: "12px", fontWeight: 700 }}
                    onClick={handleClose}
                    disabled={isLoading}
                  />
                </div>
                <div style={{ marginLeft: "-15%" }}>
                  <MailProButton
                    key={"mp_new_first_ticket_group"}
                    variant="filled"
                    label={"SUBMIT"}
                    customStyle={{ fontSize: "12px", fontWeight: 700 }}
                    onClick={handleSubmit}
                    disabled={isLoading || !helpMessage}
                  />
                </div>
                <div></div>
              </div>
            ) : (
              <div className={classes.footer}>
                <div>
                  {/* <MailProButton
                                        key={"mp_new_ticket_group"}
                                        variant="filled"
                                        label={"Submit a New Ticket"}
                                        customStyle={{ fontSize: "12px", fontWeight: 700 }}
                                        onClick={handleResubmit}
                                    /> */}
                </div>
                <div>
                  <MailProButton
                    key={"mp_new_add_close_cance;"}
                    variant="outlined"
                    label={"Close"}
                    customStyle={{ fontSize: "12px", fontWeight: 700 }}
                    onClick={handleClose}
                  />
                </div>
                <div></div>
              </div>
            )}
          </MailProDialogBody>
        </MailProDialog>
      )}
    </div>
  );
};

export default compose(withStyles(styles), withSnackbar)(MailProSupport);
