import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import ScarCoverLogo from "_img/agentLogos/scar-cover-logo.png";
import BryanUhrigQRLogo from "_img/agentLogos/bryan_uhrig_qr.png";

import CalganoHamiltonLogo from "_img/brokerage-logos/calgano-hamilton-logo.png";
import KristinArntzCoverLogo from "_img/agentLogos/kristin-arntz-cover-logo.png";

class QVFourCoverPageInfo extends Component {
  getDesignationStyle = (agentDesignation) => {
    const { isMemberOfTeam } = this.props.agentData;

    if (
      agentDesignation &&
      agentDesignation.length >= 35 &&
      agentDesignation.length < 48
    ) {
      return "smaller-agent-designation";
    } else if (agentDesignation && agentDesignation.length >= 48) {
      if (isMemberOfTeam) {
        return "xxs-agent-designation";
      } else {
        return "xs-agent-designation";
      }
    } else {
      return "";
    }
  };
  phoneCircleDivider = (num, nameLength, useHyphens) => {
    if (useHyphens) {
      return (
        <div className={`phone-num-row ${nameLength > 14 ? "small" : ""}`}>
          {num.slice(0, 3)}-{num.slice(3, 6)}-{num.slice(6)}
        </div>
      );
    } else {
      return (
        <div className={`phone-num-row ${nameLength > 14 ? "small" : ""}`}>
          {num.slice(0, 3)}
          <div className="phone-circle-divider" />
          {num.slice(3, 6)} <div className="phone-circle-divider" />
          {num.slice(6)}
        </div>
      );
    }
  };
  formatAgentDesignation = (designation) => {
    const { listgenUserID } = this.props.agentData;
    let isWendyMarioni = listgenUserID === "102935";

    if (isWendyMarioni) {
      return "Realtor® | CIPS™ | ABR™ | Relocation Specialist";
    } else if (
      designation === "Realtor" ||
      designation === "Broker Associate | Realtor"
    ) {
      return `${designation}®`;
    } else {
      return `${designation}`;
    }
  };
  render() {
    let {
      listgenUserFirstName,
      listgenUserLastName,
      agentDesignation,
      mobilePhoneNo,
      agentLicenseNumber,
      teamMembers,
      brokerageName,
      listgenUserID,
      teamName,
      specialBrokerageLogoURL,
      photoURL,
    } = this.props.agentData;

    let {
      homeOwnerURLID,
      homeOwnerURLQRCodePath,
      homeOwnerURL,
    } = this.props.hoData;
    const { formatAgentDesignation, getDesignationStyle } = this;
    const { isDemo, coverPageDetails, hasFlippedTeam } = this.props;

    const { coverPhotoCredit1, coverPhotoCredit2 } = coverPageDetails;
    const { mlsAreaID, tagLine } = this.props.currentArea;
    const { shortenedDemoBrokerage } = propertyUtil;

    let alternateBrokerageUrl = {
      compass:
        "https://pma-coverphotos.s3.amazonaws.com/Horizontal_White_Compass%20Logo_500px.png",
      cb:
        "https://pma-coverphotos.s3.amazonaws.com/cb-stacked-brokerage-logo.png",
      "village-properties":
        "https://pma-coverphotos.s3.amazonaws.com/village-properties-logo2.png",
      intero: "https://pma-coverphotos.s3.amazonaws.com/intero-cover-logo.png",
      berkshire:
        "https://pma-coverphotos.s3.amazonaws.com/bhhs-stacked-cover-logo.png",
      sothebys:
        "https://pma-coverphotos.s3.amazonaws.com/sothebys-brokerage-logo.png",
    };
    let shortDemo = shortenedDemoBrokerage(brokerageName);

    let shortDemoURL = alternateBrokerageUrl[shortDemo];

    let defaultLogo;
    if (brokerageName.includes("Compass")) {
      defaultLogo = alternateBrokerageUrl["compass"];
    } else if (brokerageName.includes("Coldwell Banker")) {
      defaultLogo = alternateBrokerageUrl["cb"];
    } else if (brokerageName.includes("Village Properties")) {
      defaultLogo = alternateBrokerageUrl["village-properties"];
    } else if (brokerageName.includes("Intero")) {
      defaultLogo = alternateBrokerageUrl["intero"];
    } else if (brokerageName.includes("Berkshire Hathaway")) {
      defaultLogo = alternateBrokerageUrl["berkshire"];
    } else if (brokerageName.includes("Sotheby's")) {
      defaultLogo = alternateBrokerageUrl["sothebys"];
    }

    if (!specialBrokerageLogoURL && !isDemo) {
      specialBrokerageLogoURL = defaultLogo;
    }

    //console.log("defaultLogo", defaultLogo);
    let slicedLogo =
      specialBrokerageLogoURL && specialBrokerageLogoURL.slice(41, -4);
    let demoSlicedLogo =
      alternateBrokerageUrl[shortDemo] &&
      alternateBrokerageUrl[shortDemo].slice(41, -4);

    let { isMemberOfTeam } = this.props.agentData;
    let agentInfoBoxStyles;
    if (brokerageName === "intero") {
      agentInfoBoxStyles = "larger-agent-info-box-width";
    } else if (listgenUserID === "105189") {
      agentInfoBoxStyles = "michael-noh-logo-and-portrait";
    } else if (slicedLogo && slicedLogo.includes("Compass_SV-logo_White")) {
      slicedLogo = "compass-sv-info-box";
    } else if (brokerageName.includes("Compass")) {
      agentInfoBoxStyles = "compass-info-box";
    }
    let specialLogoStyles;
    if (listgenUserID === "104597") {
      specialLogoStyles = "larger-compass-logo";
    }
    let coverPageInfoStyles = "";
    if (listgenUserID === "105189") {
      coverPageInfoStyles = "larger-cover-page-info-styles";
    }
    let isKatherineHunt = listgenUserID === "105805";
    const isScar = teamName === "Team Scarborough";

    if (isScar || listgenUserID === "102843") {
      isMemberOfTeam = false;
      teamMembers = [];
    }
    const isAnnAlbanese = listgenUserID === "100063";
    const isJohnsons = listgenUserID === "100988";
    const isAmberRussell = listgenUserID === "100040" && isMemberOfTeam;
    const isMarkWong = listgenUserID === "102450";
    const isLeeShantePappas = listgenUserID === "104480";
    const isSophieTsang = listgenUserID === "102567";
    const isHouy = listgenUserID === "105797";
    if (isLeeShantePappas) {
      listgenUserFirstName = "Lee + Shanté";
    }
    let isDawnThomas = listgenUserID === "102674";
    let isKyle3Team = listgenUserID === "103406";
    let isWendyMarioni = listgenUserID === "102935";

    const isUhrig = listgenUserID === "100568";
    const isLisaMcCollum = listgenUserID === "100656";

    const isKristinArntz = listgenUserID === "100462";
    const isGaryPalacios = listgenUserID === "105790";
    let presentedCreditStyle;

    if (listgenUserID === "106031") {
      presentedCreditStyle = "maurry-vano-presented-credit";
    } else if (isUhrig) {
      presentedCreditStyle = "uhrig";
    } else if (isLisaMcCollum || isDawnThomas) {
      presentedCreditStyle = "dawn-thomas";
    } else if (isScar) {
      presentedCreditStyle = "scar";
    } else if (isKatherineHunt) {
      presentedCreditStyle = "mccollum";
    } else if (isDawnThomas) {
      presentedCreditStyle = "mccollum";
    } else if (isKristinArntz) {
      presentedCreditStyle = "arntz";
    } else if (isGaryPalacios) {
      presentedCreditStyle = "arntz";
    } else if (isAnnAlbanese || listgenUserID === "101567") {
      presentedCreditStyle = "albanese";
    } else if (
      listgenUserID === "102712" ||
      listgenUserID === "101268" ||
      isKyle3Team
    ) {
      presentedCreditStyle = "three-agent-presented";
    }
    // console.log("isUhrig", isUhrig);
    const { phoneCircleDivider } = this;
    let fullName = `${listgenUserFirstName} ${listgenUserLastName}`;
    let secondAgent = "agent";
    if (isMemberOfTeam) {
      secondAgent = `${teamMembers[1].firstName} ${teamMembers[1].lastName}`;
    }
    let isGummow = listgenUserID === "101566";

    let smallerNameAndPhoneFont =
      fullName.length > 18 || secondAgent.length > 18 || isGummow
        ? true
        : false;
    let isVait = listgenUserID === "101001";
    let hyphensOnPhoneNumber = false;
    if (listgenUserID === "103486") {
      hyphensOnPhoneNumber = true;
    }
    let isNegarFatemi = listgenUserID === "102734";
    let isKerrySmith = listgenUserID === "102340";
    let isErikaDemma = listgenUserID === "106107";
    let stackedNameNumberTemplate = isNegarFatemi ? true : false;
    let isJeanMarie = listgenUserID === "107012";
    let smallerAgentDesignation =
      agentDesignation &&
      agentDesignation.length >= 35 &&
      agentDesignation.length < 48;
    let xsAgentDesignation = agentDesignation && agentDesignation.length >= 48;

    let coverPageInfoRowStyles = "";
    if (isNegarFatemi || listgenUserID === "104626") {
      coverPageInfoRowStyles = "higher-cover-info-1";
      presentedCreditStyle = "higher-presented-by-1";
      agentInfoBoxStyles = "lower-cover-logo-1";
    }
    if (
      (listgenUserID === "106421" &&
        !coverPhotoCredit1 &&
        !coverPhotoCredit2) ||
      (listgenUserID === "104480" && !coverPhotoCredit1 && !coverPhotoCredit2)
    ) {
      coverPageInfoRowStyles = "lower-cover-info-bottom-55";
    }
    if (listgenUserID === "104626") {
      specialLogoStyles = "raised-up-compass-logo";
    }
    if (listgenUserID === "102378" || listgenUserID === "102450") {
      coverPageInfoRowStyles = "higher-cover-info-2";
    }
    let useTeamName = false;
    let teamNameText = "";
    if (listgenUserID === "101332") {
      teamNameText = "The Locals Team";
      useTeamName = true;
    }

    if (isGummow) {
      return (
        <div className="gummow-qv4-cover-page-info-row">
          <div className="gummow-cover-info-portrait">
            <img src={photoURL} alt="Gummow logo" />
          </div>
          <div className="gummow-qv4-cover-page-info">
            <div className="gummow-agent-info-presented-by">Presented by</div>
            <div className="gummow-transparent-inner-container">
              <div className="gummow-agent-info-section">
                <div className="gummow-agent-info-col">
                  <div className="gummow-agent-and-phone">
                    <p className={`gummow-agent-name gummow-name-and-phone`}>
                      Brandon & Nick Gummow
                    </p>
                  </div>

                  <div className="gummow-agent-and-phone">
                    <div className={`gummow-agent-phone gummow-name-and-phone`}>
                      {phoneCircleDivider("4083579088", fullName.length)}
                    </div>
                  </div>

                  <div className="gummow-designation-and-dre gummow-des-and-dre-col">
                    <p className="gummow-agent-designation">
                      WALLSTREET JOURNAL TOP PRODUCERS
                    </p>
                    <div className="gummow-custom-dre-row">
                      <p className="gummow-dre-word">DRE</p>
                      <p className="gummow-dre-num"> # {agentLicenseNumber}</p>
                      <p className="gummow-mx-6"> | </p>
                      <p className="gummow-dre-word">DRE</p>
                      <p className="gummow-dre-num">
                        {" "}
                        # {teamMembers[1].agentLicenseNumber}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className={`gummow-agent-info-box-logo ${agentInfoBoxStyles}`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`qv4-cover-page-info-row ${
            isGummow && "gummow-qv4-cover-page-info-row"
          } ${coverPageInfoRowStyles}`}
        >
          {isDawnThomas && (
            <div className="dawn-thomas-cover-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/non-covers/052024_DawnThomas_FrontCov_Headshot.jpg"
                alt="Dawn Thomas Photo"
              />
            </div>
          )}

          {mlsAreaID === "SCL-16N" && listgenUserID === "101649" && (
            <div className="scar-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/monte-serreno-cover-logo.png"
                alt="Monte Serreno logo"
              />
            </div>
          )}

          {mlsAreaID === "SCL-16A" && listgenUserID === "101649" && (
            <div className="scar-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/perry-los-gatos-town.png"
                alt="Los Gatos Town logo"
              />
            </div>
          )}

          {isScar && (
            <div className="scar-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/scar-cover-logo.png"
                alt="Scar logo"
              />
            </div>
          )}
          {isKatherineHunt && (
            <div className="katherine-hunt-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/non-covers/katherine-hunt-qr-code.png"
                alt="Katherine Hunt QR Code"
              />
            </div>
          )}
          {isJohnsons && (
            <div className="johnsons-qr-code">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/johnsons-qr-code.png"
                alt="Johnsons logo"
              />
            </div>
          )}

          {isGummow && (
            <div className="gummow-cover-info-portrait">
              <img src={photoURL} alt="Gummow logo" />
            </div>
          )}

          {isUhrig && (
            <div className="uhrig-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/bryan_uhrig_qr.png"
                alt="Bryan Uhrig logo"
              />
            </div>
          )}
          {/*//david kim*/}
          {listgenUserID === "100778" && (
            <div className="johnsons-qr-code">
              <img src="https://pma-coverphotos.s3.amazonaws.com/david-kim-qr-feb-2024.png" />
            </div>
          )}
          {isGaryPalacios && (
            <div className="gary-palacios-cover-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/non-covers/092024_GPalacios_Logo_wht-reverse.png"
                alt="Gary Palacios logo"
              />
            </div>
          )}
          {isKristinArntz && (
            <div className="kristin-arntz-cover-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/kristin-arntz-cover-logo.png"
                alt="Kristin Arntz logo"
              />
            </div>
          )}
          {isLisaMcCollum && (
            <div className="lisa-mccollum-cover-logo">
              <img
                src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/111524_LisaMcC_frontCovr.jpg"
                alt="Calgano Hamilton Logo"
              />
            </div>
          )}
          {isAnnAlbanese && (
            <div className="global-luxury-cb-cover-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/pres_circle_bronze_500x500px.png"
                alt="Global Luxury Coldwell Banker Logo"
              />
            </div>
          )}
          {listgenUserID === "101567" && (
            <div className="global-luxury-cb-cover-logo">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/INternational+Presidents+Elite+BlackSIL.png"
                alt="Global Luxury Coldwell Banker Logo"
              />
            </div>
          )}

          {isVait && (
            <div className="vait-cover-logo">
              <img src={photoURL} alt="Annette Vait Profile Photo" />
            </div>
          )}

          {isVait ? (
            <div className={"vait-cover-info"}>
              <div className="vait-transparent-inner-container">
                <div className={"vait-agent-info-section"}>
                  <div className="vait-agent-info-col">
                    <div className="vait-agent-and-phone">
                      <p
                        className={`vait-agent-name ${
                          smallerNameAndPhoneFont
                            ? "smaller-name-and-phone"
                            : ""
                        } `}
                      >
                        {listgenUserFirstName} {listgenUserLastName}
                      </p>
                    </div>
                    <div className={`vait-row`}>
                      <p className="vait-designation caps">Realtor </p>{" "}
                      <p className="vait-designation-span">-</p>
                      <span className="vait-mx-2"></span>
                      <p className="vait-designation">Broker Associate</p>
                    </div>
                    <div className="vait-designation-and-dre">
                      <p className="vait-dre-word">DRE</p>
                      <p className="vait-dre-num"> # {agentLicenseNumber}</p>
                    </div>
                    <div className={`vait-agent-phone-number`}>
                      {phoneCircleDivider(mobilePhoneNo, fullName.length)}
                    </div>
                  </div>

                  <div className={`agent-info-box-logo ${agentInfoBoxStyles}`}>
                    {specialBrokerageLogoURL ? (
                      <img
                        className={`${slicedLogo} ${
                          isMemberOfTeam && "team-logo"
                        } ${specialLogoStyles}`}
                        src={specialBrokerageLogoURL}
                        alt={specialBrokerageLogoURL}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : isNegarFatemi ? (
            <div className="qv4-cover-page-info">
              <div
                className={`agent-info-presented-by ${presentedCreditStyle}`}
              >
                Presented by
              </div>
              <div className="transparent-inner-container">
                <div className="agent-info-section">
                  <div className="agent-info-col bigger-bolder-agent-info-col">
                    <div className="agent-and-phone">
                      <p
                        className={`agent-name ${
                          smallerNameAndPhoneFont
                            ? "smaller-name-and-phone"
                            : ""
                        } `}
                      >
                        {listgenUserFirstName} {listgenUserLastName}
                      </p>
                    </div>
                    <p className="cover-photo-tagline">{tagLine}</p>
                    <div className={`agent-phone`}>
                      {phoneCircleDivider(mobilePhoneNo, fullName.length)}
                    </div>
                    <div className="designation-and-dre">
                      <p
                        className={`agent-designation ${
                          smallerAgentDesignation
                            ? "smaller-agent-designation"
                            : ""
                        } `}
                      >
                        {formatAgentDesignation(agentDesignation)}
                      </p>
                      <p className="dre-word">DRE</p>
                      <p className="dre-num"> # {agentLicenseNumber}</p>
                    </div>
                  </div>

                  <div
                    className={`agent-info-box-logo ${
                      brokerageName.includes("Keller Williams") &&
                      "longer-agent-info-box-logo"
                    } ${agentInfoBoxStyles}`}
                  >
                    <img
                      className={`${slicedLogo} ${specialLogoStyles}`}
                      src={
                        specialBrokerageLogoURL
                          ? specialBrokerageLogoURL
                          : defaultLogo
                      }
                      alt={specialBrokerageLogoURL}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : listgenUserID === "100528" ? (
            <div className="qv4-cover-page-info douglas-info">
              <div
                className={`agent-info-presented-by ${presentedCreditStyle}`}
              >
                Presented by
              </div>
              <div className="transparent-inner-container">
                <div className="agent-info-section">
                  <div className="team-info-col">
                    <div className="team-agent-container">
                      <div className="team-agent-col">
                        <div className="team-agent-and-phone">
                          <div className="team-agent-name larger-agent-name">
                            {listgenUserFirstName} {listgenUserLastName}
                          </div>
                          <div className="team-vertical-divider" />
                          <div className="team-agent-phone larger-phone-num">
                            {phoneCircleDivider(mobilePhoneNo)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="team-agent-container">
                      <div className="team-agent-col">
                        <div className="team-agent-and-phone">
                          <p className="team-agent-name">
                            Rachael Douglas & Associates
                          </p>
                          <div className="team-vertical-divider" />
                          <div className="team-designation-and-dre">
                            <div className="dre-row">
                              <p className="dre-word">dre</p>
                              <p className="dre-num"> #{agentLicenseNumber}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`agent-info-box-logo ${
                      brokerageName.includes("Keller Williams") &&
                      "longer-agent-info-box-logo"
                    } ${
                      listgenUserID === "101567" && "agent-logo-mr"
                    } ${agentInfoBoxStyles}`}
                  >
                    <img
                      className={`${slicedLogo} douglas-specialized-logo ${specialLogoStyles}`}
                      src={specialBrokerageLogoURL}
                      alt={specialBrokerageLogoURL}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : listgenUserID === "104504" ? (
            <div
              className={
                "qv4-cover-page-info " +
                (isMemberOfTeam ? "team " : "") +
                "custom-sam-and-gina-cover-info"
              }
            >
              <div
                className={`agent-info-presented-by sam-and-gina-custom-presented`}
              >
                Presented by
              </div>
              <div className="transparent-inner-container">
                <div
                  className={
                    "agent-info-section " + (isMemberOfTeam ? "team" : "")
                  }
                >
                  {isMemberOfTeam && !isGummow && !isLeeShantePappas ? (
                    <div className="small-team-info-col">
                      <div className={`small-team-agent-container`}>
                        <div className="small-team-headshot-container">
                          <img
                            className="small-team-headshot-img"
                            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/gina-m-headshot.jpg"
                            alt="Gina Headshot"
                          />
                        </div>
                        <div className="small-team-agent-col">
                          <div className="small-team-agent-and-phone">
                            <div
                              className={
                                "small-team-agent-name " +
                                (smallerNameAndPhoneFont
                                  ? "small-smaller-name-and-phone"
                                  : "")
                              }
                            >
                              {listgenUserFirstName} {listgenUserLastName}
                            </div>

                            <div className="small-team-vertical-divider" />
                            <div
                              className={
                                "small-team-agent-phone " +
                                (smallerNameAndPhoneFont
                                  ? "smaller-name-and-phone"
                                  : "")
                              }
                            >
                              {phoneCircleDivider(
                                mobilePhoneNo,
                                null,
                                hyphensOnPhoneNumber
                              )}
                            </div>
                          </div>
                          <div className={`small-team-sub-info`}>
                            <div className="small-team-designation-and-dre">
                              <p
                                className={`small-designation-text ${
                                  smallerNameAndPhoneFont
                                    ? "small-smaller-designation"
                                    : ""
                                } `}
                              >
                                {formatAgentDesignation(agentDesignation)}
                              </p>
                              <div className="small-dre-row">
                                <p className="small-dre-word">dre</p>{" "}
                                <p className="small-dre-num">
                                  #{agentLicenseNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`small-team-agent-container`}>
                        <div className="small-team-headshot-container">
                          <img
                            className="small-team-headshot-img"
                            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/samantha-s-headshot.jpg"
                            alt="Samantha Headshot"
                          />
                        </div>
                        <div className="small-team-agent-col">
                          <div className="small-team-agent-and-phone">
                            <p
                              className={
                                "small-team-agent-name " +
                                (smallerNameAndPhoneFont
                                  ? "small-smaller-name-and-phone"
                                  : "")
                              }
                            >
                              {teamMembers[1].firstName}{" "}
                              {teamMembers[1].lastName}
                            </p>
                            <div className="small-team-vertical-divider" />
                            {listgenUserID !== "100027" && (
                              <div
                                className={
                                  "small-team-agent-phone " +
                                  (smallerNameAndPhoneFont
                                    ? "small-smaller-name-and-phone"
                                    : "")
                                }
                              >
                                {phoneCircleDivider(
                                  teamMembers[1].phone,
                                  null,
                                  hyphensOnPhoneNumber
                                )}
                              </div>
                            )}
                          </div>
                          <div className={`small-team-sub-info`}>
                            <div className="small-team-designation-and-dre">
                              <p
                                className={`small-designation-text ${
                                  smallerNameAndPhoneFont
                                    ? "small-smaller-designation"
                                    : ""
                                } `}
                              >
                                {formatAgentDesignation(
                                  teamMembers[1].agentDesignation
                                )}
                              </p>
                              <div className="small-dre-row">
                                <p className="small-dre-word">dre</p>
                                <p className="small-dre-num">
                                  {" "}
                                  #
                                  {formatAgentDesignation(
                                    teamMembers[1].agentLicenseNumber
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="agent-info-col">
                      <div className="agent-and-phone">
                        {isGummow ? (
                          <p className={`agent-name gummow-name-and-phone`}>
                            Brandon & Nick Gummow
                          </p>
                        ) : listgenUserID === "100988" ? (
                          <p className="agent-name smaller-name-and-phone">
                            Dianne & Brianna {listgenUserLastName}
                          </p>
                        ) : (
                          <p
                            className={`agent-name ${
                              smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : ""
                            } `}
                          >
                            {listgenUserFirstName} {listgenUserLastName}
                          </p>
                        )}
                        <div className="vertical-divider" />
                        {!isGummow && (
                          <div
                            className={`agent-phone ${
                              smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : ""
                            } `}
                          >
                            {phoneCircleDivider(mobilePhoneNo, fullName.length)}
                          </div>
                        )}
                      </div>
                      {isGummow && (
                        <div className="agent-and-phone">
                          <div className={`agent-phone gummow-name-and-phone`}>
                            {phoneCircleDivider("4083579088", fullName.length)}
                          </div>
                        </div>
                      )}
                      {isGummow ? (
                        <div className="designation-and-dre des-and-dre-col">
                          <p
                            className={`agent-designation ${
                              smallerNameAndPhoneFont
                                ? "smaller-agent-designation"
                                : ""
                            } `}
                          >
                            WALLSTREET JOURNAL TOP PRODUCERS
                          </p>
                          <div className="custom-dre-row">
                            <p className="dre-word">DRE</p>
                            <p className="dre-num"> # {agentLicenseNumber}</p>
                            <p className="mx-6"> / </p>
                            <p className="dre-word">DRE</p>
                            <p className="dre-num">
                              {" "}
                              # {teamMembers[1].agentLicenseNumber}
                            </p>
                          </div>
                        </div>
                      ) : isScar ? (
                        <div className="designation-and-dre">
                          <p
                            className={`agent-designation ${
                              smallerNameAndPhoneFont
                                ? "smaller-agent-designation"
                                : ""
                            } `}
                          >
                            Realtors
                          </p>

                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # 01182792 </p>
                          <p className="mx-6"> / </p>
                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # 01050912</p>
                        </div>
                      ) : isJohnsons ? (
                        <div className="designation-and-dre">
                          <p
                            className={`agent-designation ${
                              smallerNameAndPhoneFont
                                ? "smaller-agent-designation"
                                : ""
                            } `}
                          >
                            Realtors
                          </p>
                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # 00947199 </p>
                          <p className="mx-6"> / </p>
                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # 01943572</p>
                        </div>
                      ) : (
                        <div className="designation-and-dre">
                          <p
                            className={`agent-designation ${
                              smallerAgentDesignation
                                ? "smaller-agent-designation"
                                : ""
                            } `}
                          >
                            {formatAgentDesignation(agentDesignation)}
                          </p>
                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # {agentLicenseNumber}</p>
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={`agent-info-box-logo ${
                      brokerageName.includes("Keller Williams") &&
                      "longer-agent-info-box-logo"
                    } ${
                      (isScar ||
                        isKatherineHunt ||
                        isUhrig ||
                        isLisaMcCollum ||
                        isAnnAlbanese ||
                        isDawnThomas ||
                        listgenUserID === "101567" ||
                        mlsAreaID === "SCL-16N" ||
                        mlsAreaID === "SCL-16A") &&
                      "agent-logo-mr"
                    } ${agentInfoBoxStyles}`}
                  >
                    {isDemo ? (
                      <img
                        className={`${demoSlicedLogo} ${
                          isMemberOfTeam && "team-logo"
                        }`}
                        src={shortDemoURL}
                        alt={`${brokerageName} logo`}
                      />
                    ) : specialBrokerageLogoURL ? (
                      <img
                        className={`${slicedLogo} ${
                          isMemberOfTeam && "team-logo"
                        } ${specialLogoStyles}`}
                        src={
                          specialBrokerageLogoURL
                            ? specialBrokerageLogoURL
                            : defaultLogo
                        }
                        alt={specialBrokerageLogoURL}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : hasFlippedTeam ? (
            <div
              className={`qv4-cover-page-info ${isMemberOfTeam && "team"} ${
                coverPageInfoStyles && coverPageInfoStyles
              }`}
            >
              <div
                className={`agent-info-presented-by ${presentedCreditStyle}`}
              >
                Presented by
              </div>
              <div className="transparent-inner-container">
                <div
                  className={
                    "agent-info-section " + (isMemberOfTeam ? "team" : "")
                  }
                >
                  <div className="team-info-col">
                    <div className={`team-agent-container`}>
                      <div className="team-agent-col">
                        <div className="team-agent-and-phone">
                          <div
                            className={
                              "team-agent-name " +
                              (smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : "")
                            }
                          >
                            {teamMembers[1].firstName} {teamMembers[1].lastName}
                          </div>

                          <div className="team-vertical-divider" />
                          <div
                            className={
                              "team-agent-phone " +
                              (smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : "")
                            }
                          >
                            {phoneCircleDivider(
                              teamMembers[1].phone,
                              null,
                              hyphensOnPhoneNumber
                            )}
                          </div>
                        </div>
                        <div className={`team-sub-info`}>
                          <div className="team-designation-and-dre">
                            <p
                              className={`designation-text ${
                                smallerNameAndPhoneFont
                                  ? "smaller-designation"
                                  : ""
                              } `}
                            >
                              {formatAgentDesignation(
                                teamMembers[1].agentDesignation
                              )}
                            </p>

                            <div className="dre-row">
                              <p className="dre-word">dre</p>{" "}
                              <p className="dre-num">
                                #
                                {formatAgentDesignation(
                                  teamMembers[1].agentLicenseNumber
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`team-agent-container`}>
                      <div className="team-agent-col">
                        <div className="team-agent-and-phone">
                          <p
                            className={
                              "team-agent-name " +
                              (smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : "")
                            }
                          >
                            {listgenUserFirstName} {listgenUserLastName}
                          </p>
                          <div className="team-vertical-divider" />

                          <div
                            className={
                              "team-agent-phone " +
                              (smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : "")
                            }
                          >
                            {phoneCircleDivider(
                              mobilePhoneNo,
                              null,
                              hyphensOnPhoneNumber
                            )}
                          </div>
                        </div>
                        <div className={`team-sub-info`}>
                          <div className="team-designation-and-dre">
                            <p
                              className={`designation-text ${
                                smallerNameAndPhoneFont
                                  ? "smaller-designation"
                                  : ""
                              } `}
                            >
                              {formatAgentDesignation(agentDesignation)}
                            </p>

                            <div className="dre-row">
                              <p className="dre-word">dre</p>
                              <p className="dre-num"> #{agentLicenseNumber}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`agent-info-box-logo  ${agentInfoBoxStyles}`}>
                    {specialBrokerageLogoURL ? (
                      <img
                        className={`${slicedLogo} ${
                          isMemberOfTeam && "team-logo"
                        } ${specialLogoStyles}`}
                        src={
                          specialBrokerageLogoURL
                            ? specialBrokerageLogoURL
                            : defaultLogo
                        }
                        alt={specialBrokerageLogoURL}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`qv4-cover-page-info ${isMemberOfTeam && "team"} ${
                listgenUserID === "" && "cover-info-three-team"
              } ${isGummow && "qv4-gummow-cover-page-info"} ${
                coverPageInfoStyles && coverPageInfoStyles
              }`}
            >
              <div
                className={`agent-info-presented-by ${presentedCreditStyle}`}
              >
                {mlsAreaID === "1522155"
                  ? ""
                  : listgenUserID === "105795"
                  ? "Presented by the Celotti Group"
                  : "Presented by"}{" "}
                {useTeamName && teamNameText}
              </div>
              <div className="transparent-inner-container">
                <div
                  className={
                    "agent-info-section " + (isMemberOfTeam ? "team" : "")
                  }
                >
                  {isMemberOfTeam &&
                  !isGummow &&
                  !isKerrySmith &&
                  !isJohnsons &&
                  !isLeeShantePappas ? (
                    <div className="team-info-col">
                      <div className={`team-agent-container`}>
                        <div className="team-agent-col">
                          <div className="team-agent-and-phone">
                            {listgenUserID === "103486" ? (
                              <div
                                className={
                                  "team-agent-name " +
                                  (smallerNameAndPhoneFont
                                    ? "smaller-name-and-phone"
                                    : "")
                                }
                              >
                                {listgenUserFirstName} G. {listgenUserLastName}
                              </div>
                            ) : (
                              <div
                                className={
                                  "team-agent-name " +
                                  (smallerNameAndPhoneFont
                                    ? "smaller-name-and-phone"
                                    : "")
                                }
                              >
                                {listgenUserFirstName} {listgenUserLastName}
                              </div>
                            )}

                            <div className="team-vertical-divider" />
                            <div
                              className={
                                "team-agent-phone " +
                                (smallerNameAndPhoneFont
                                  ? "smaller-name-and-phone"
                                  : "")
                              }
                            >
                              {phoneCircleDivider(
                                mobilePhoneNo,
                                null,
                                hyphensOnPhoneNumber
                              )}
                            </div>
                          </div>
                          <div className={`team-sub-info`}>
                            {listgenUserID === "105896" ? (
                              <div className="team-designation-and-dre column-designation-dre">
                                <p
                                  className={`designation-text  ${
                                    (xsAgentDesignation ||
                                      smallerAgentDesignation) &&
                                    "xs-agent-designation"
                                  }`}
                                >
                                  {formatAgentDesignation(agentDesignation)}
                                </p>

                                <div className="dre-row">
                                  <p className="dre-word">DRE</p>

                                  <p className="dre-num">
                                    {" "}
                                    # {agentLicenseNumber}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="team-designation-and-dre">
                                {!isMarkWong && (
                                  <p
                                    className={`designation-text ${
                                      smallerNameAndPhoneFont
                                        ? "smaller-designation"
                                        : ""
                                    } `}
                                  >
                                    {formatAgentDesignation(agentDesignation)}
                                  </p>
                                )}
                                <div className="dre-row">
                                  <p className="dre-word">dre</p>{" "}
                                  <p className="dre-num">
                                    #{agentLicenseNumber}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={`team-agent-container`}>
                        <div className="team-agent-col">
                          <div className="team-agent-and-phone">
                            <p
                              className={
                                "team-agent-name " +
                                (smallerNameAndPhoneFont
                                  ? "smaller-name-and-phone"
                                  : "")
                              }
                            >
                              {teamMembers[1].firstName}{" "}
                              {teamMembers[1].lastName}
                            </p>
                            <div className="team-vertical-divider" />
                            {listgenUserID !== "100027" && (
                              <div
                                className={
                                  "team-agent-phone " +
                                  (smallerNameAndPhoneFont
                                    ? "smaller-name-and-phone"
                                    : "")
                                }
                              >
                                {phoneCircleDivider(
                                  teamMembers[1].phone,
                                  null,
                                  hyphensOnPhoneNumber
                                )}
                              </div>
                            )}
                          </div>
                          <div className={`team-sub-info`}>
                            <div className="team-designation-and-dre">
                              {!isMarkWong && (
                                <p
                                  className={`designation-text ${
                                    smallerNameAndPhoneFont
                                      ? "smaller-designation"
                                      : ""
                                  } `}
                                >
                                  {formatAgentDesignation(
                                    teamMembers[1].agentDesignation
                                  )}
                                </p>
                              )}

                              <div className="dre-row">
                                <p className="dre-word">dre</p>
                                <p className="dre-num">
                                  {" "}
                                  #
                                  {formatAgentDesignation(
                                    teamMembers[1].agentLicenseNumber
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>

                          {listgenUserID === "106031" && (
                            <div className="maurry-cover-info-row">
                              <img
                                src="https://pma-coverphotos.s3.amazonaws.com/non-covers/cropped-maurry-vano-white-transparent.png"
                                alt="Vano Maurry Logo"
                                className="maurry-cover-info-agent-logo"
                              />{" "}
                              <img
                                className="maurry-cover-info-brokerage-logo"
                                src={
                                  specialBrokerageLogoURL
                                    ? specialBrokerageLogoURL
                                    : defaultLogo
                                }
                                alt={specialBrokerageLogoURL}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {listgenUserID === "102712" ||
                      listgenUserID === "101268" ||
                      isKyle3Team ? (
                        <div className="team-agent-container smaller-height">
                          <div className="team-agent-col">
                            <div className="team-agent-and-phone">
                              <p
                                className={
                                  "team-agent-name " +
                                  (smallerNameAndPhoneFont
                                    ? "smaller-name-and-phone"
                                    : "")
                                }
                              >
                                {teamMembers[2].firstName}{" "}
                                {teamMembers[2].lastName}
                              </p>
                              <div className="team-vertical-divider" /> 
                              <div
                                className={
                                  "team-agent-phone " +
                                  (smallerNameAndPhoneFont
                                    ? "smaller-name-and-phone"
                                    : "")
                                }
                              >
                                {phoneCircleDivider(
                                  teamMembers[2].phone,
                                  null,
                                  hyphensOnPhoneNumber
                                )}
                              </div>
                            </div>
                            <div className={`team-sub-info`}>
                              <div className="team-designation-and-dre">
                                <p
                                  className={`designation-text ${
                                    smallerNameAndPhoneFont
                                      ? "smaller-designation"
                                      : ""
                                  } ${getDesignationStyle(
                                    teamMembers[2].agentDesignation
                                  )}`}
                                >
                                  {formatAgentDesignation(
                                    teamMembers[2].agentDesignation
                                  )}
                                </p>
                                <div className="dre-row">
                                  <p className="dre-word">dre</p>
                                  <p className="dre-num">
                                    {" "}
                                    #
                                    {formatAgentDesignation(
                                      teamMembers[2].agentLicenseNumber
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : isKerrySmith ? (
                    <div className="agent-info-col">
                      <div className="agent-and-phone">
                        <p
                          className={`agent-name ${
                            smallerNameAndPhoneFont
                              ? "smaller-name-and-phone"
                              : ""
                          } `}
                        >
                          {teamName}
                        </p>

                        <div className="vertical-divider" />

                        <div
                          className={`agent-phone ${
                            smallerNameAndPhoneFont
                              ? "smaller-name-and-phone"
                              : ""
                          } `}
                        >
                          {phoneCircleDivider(mobilePhoneNo, fullName.length)}
                        </div>
                      </div>

                      <div className="designation-and-dre">
                        <p
                          className={`agent-designation ${
                            smallerAgentDesignation
                              ? "smaller-agent-designation"
                              : ""
                          } `}
                        >
                          {formatAgentDesignation(agentDesignation)}
                        </p>
                        <p className="dre-word">DRE</p>
                        <p className="dre-num"> # {agentLicenseNumber}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="agent-info-col">
                      <div className="agent-and-phone">
                        {isGummow ? (
                          <p className={`agent-name gummow-name-and-phone`}>
                            Brandon & Nick Gummow
                          </p>
                        ) : listgenUserID === "100988" ? (
                          <p className="agent-name smaller-name-and-phone">
                            Dianne & Brianna {listgenUserLastName}
                          </p>
                        ) : listgenUserID === "102843" ? (
                          <p
                            className={`agent-name ${
                              smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : ""
                            } `}
                          >
                            Galli Team
                          </p>
                        ) : (
                          <p
                            className={`agent-name ${
                              smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : ""
                            } `}
                          >
                            {listgenUserFirstName}{" "}
                            {listgenUserID !== "104835"
                              ? listgenUserLastName
                              : ""}
                          </p>
                        )}
                        <div className="vertical-divider" />
                        {!isGummow && !isJeanMarie && (
                          <div
                            className={`agent-phone ${
                              smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : ""
                            } `}
                          >
                            {phoneCircleDivider(mobilePhoneNo, fullName.length)}
                          </div>
                        )}
                      </div>
                      {isGummow && (
                        <div className="agent-and-phone">
                          <div className={`agent-phone gummow-name-and-phone`}>
                            {phoneCircleDivider("4083579088", fullName.length)}
                          </div>
                        </div>
                      )}

                      {isJeanMarie && (
                        <div className="agent-and-phone">
                          <div
                            className={`agent-phone ${
                              smallerNameAndPhoneFont
                                ? "smaller-name-and-phone"
                                : ""
                            } `}
                          >
                            {phoneCircleDivider(mobilePhoneNo, 1)}
                          </div>
                        </div>
                      )}

                      {isGummow ? (
                        <div className="designation-and-dre des-and-dre-col">
                          <p
                            className={`agent-designation ${
                              smallerNameAndPhoneFont
                                ? "smaller-agent-designation"
                                : ""
                            } `}
                          >
                            WALLSTREET JOURNAL TOP PRODUCERS
                          </p>
                          <div className="custom-dre-row">
                            <p className="dre-word">DRE</p>
                            <p className="dre-num"> # {agentLicenseNumber}</p>
                            <p className="mx-6"> / </p>
                            <p className="dre-word">DRE</p>
                            <p className="dre-num">
                              {" "}
                              # {teamMembers[1].agentLicenseNumber}
                            </p>
                          </div>
                        </div>
                      ) : isScar ? (
                        <div className="designation-and-dre">
                          <p
                            className={`agent-designation ${
                              smallerNameAndPhoneFont
                                ? "smaller-agent-designation"
                                : ""
                            } `}
                          >
                            Realtors
                          </p>

                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # 01182792 </p>
                          <p className="mx-6"> / </p>
                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # 01050912</p>
                        </div>
                      ) : isJohnsons ? (
                        <div className="designation-and-dre">
                          <p
                            className={`agent-designation ${
                              smallerNameAndPhoneFont
                                ? "smaller-agent-designation"
                                : ""
                            } `}
                          >
                            Realtors
                          </p>
                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # 00947199 </p>
                          <p className="mx-6"> / </p>
                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # 01943572</p>
                        </div>
                      ) : isWendyMarioni || listgenUserID === "105100" ? (
                        <div className="designation-and-dre designation-col">
                          <div className="designation-full-row">
                            <p
                              className={`agent-designation ${
                                smallerAgentDesignation
                                  ? "smaller-agent-designation"
                                  : ""
                              } `}
                            >
                              {formatAgentDesignation(agentDesignation)}
                            </p>
                          </div>

                          <div className="designation-full-row">
                            <p className="dre-word">DRE</p>
                            <p className="dre-num"> # {agentLicenseNumber}</p>
                          </div>
                        </div>
                      ) : listgenUserID === "102378" ? (
                        <div className="designation-and-dre designation-col">
                          <div className="designation-full-row">
                            <p
                              className={`agent-designation ${
                                smallerAgentDesignation
                                  ? "smaller-agent-designation"
                                  : ""
                              } `}
                            >
                              {formatAgentDesignation(agentDesignation)}
                            </p>
                            <p className="dre-word">DRE</p>
                            <p className="dre-num"> # {agentLicenseNumber}</p>
                          </div>
                          <div className="designation-full-row">
                            <p
                              className={`agent-designation ${
                                smallerAgentDesignation
                                  ? "smaller-agent-designation"
                                  : ""
                              } `}
                            >
                              CDPE — Certified Distressed Property Expert
                            </p>
                          </div>
                          <div className="designation-full-row">
                            <p
                              className={`agent-designation ${
                                smallerAgentDesignation
                                  ? "smaller-agent-designation"
                                  : ""
                              } `}
                            >
                              SRES — Senior Real Estate Specialist
                            </p>
                          </div>
                          <div className="designation-full-row">
                            <p
                              className={`agent-designation ${
                                smallerAgentDesignation
                                  ? "smaller-agent-designation"
                                  : ""
                              } `}
                            >
                              ABR — Accredited Buyer Representative
                            </p>
                          </div>
                        </div>
                      ) : xsAgentDesignation ? (
                        <div
                          className={`designation-and-dre ${
                            xsAgentDesignation && "column-designation-dre"
                          }`}
                        >
                          <p
                            className={`agent-designation ${
                              smallerAgentDesignation
                                ? "smaller-agent-designation"
                                : ""
                            } ${xsAgentDesignation && "xs-agent-designation"} `}
                          >
                            {formatAgentDesignation(agentDesignation)}
                          </p>
                          <div className="dre-row">
                            <p className="dre-word">DRE</p>
                            <p className="dre-num"> # {agentLicenseNumber}</p>
                          </div>
                        </div>
                      ) : listgenUserID === "102843" ? (
                        <div className="designation-and-dre">
                          <p
                            className={`agent-designation ${
                              smallerAgentDesignation
                                ? "smaller-agent-designation"
                                : ""
                            }`}
                          >
                            {formatAgentDesignation(agentDesignation)}
                          </p>
                          <p className="name-before-dre">Michael Galli</p>
                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # {agentLicenseNumber}</p>
                        </div>
                      ) : isErikaDemma ? (
                        <div className="designation-and-dre designation-col">
                          <div className="designation-full-row">
                            <p className="agent-designation smaller-agent-designation capitalized-agent-designation">
                              Call Erika for Off Market Opportunities |
                              ErikaDemma.com
                            </p>
                          </div>
                          <div className="designation-full-row">
                            <p className="agent-designation smaller-agent-designation capitalized-agent-designation agent-designation-mb-10">
                              Instagram: @luxrealestateservices
                            </p>
                          </div>

                          <div className="designation-full-row">
                            <p
                              className={`agent-designation ${
                                smallerAgentDesignation
                                  ? "smaller-agent-designation"
                                  : ""
                              } `}
                            >
                              {formatAgentDesignation(agentDesignation)}
                            </p>
                            <p className="dre-word">DRE</p>
                            <p className="dre-num"> # {agentLicenseNumber}</p>
                          </div>
                        </div>
                      ) : isSophieTsang ? (
                        <div className="designation-and-dre designation-col">
                          <div className="designation-full-row">
                            <p className="agent-designation smaller-agent-designation capitalized-agent-designation">
                              MBA, Stanford GSB, Certified Residential
                              Specialist
                            </p>
                          </div>
                          <div className="designation-full-row">
                            <p className="agent-designation smaller-agent-designation capitalized-agent-designation agent-designation-mb-10">
                              WSJ RealTrends America's Best 2024
                            </p>
                          </div>

                          <div className="designation-full-row">
                            <p
                              className={`agent-designation ${
                                smallerAgentDesignation
                                  ? "smaller-agent-designation"
                                  : ""
                              } `}
                            >
                              {formatAgentDesignation(agentDesignation)}
                            </p>
                            <p className="dre-word">DRE</p>
                            <p className="dre-num"> # {agentLicenseNumber}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="designation-and-dre">
                          <p
                            className={`agent-designation ${
                              smallerAgentDesignation
                                ? "smaller-agent-designation"
                                : ""
                            }`}
                          >
                            {formatAgentDesignation(agentDesignation)}
                          </p>

                          <p className="dre-word">DRE</p>
                          <p className="dre-num"> # {agentLicenseNumber}</p>
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={`agent-info-box-logo ${
                      brokerageName.includes("Keller Williams") &&
                      "longer-agent-info-box-logo"
                    } ${
                      (isScar ||
                        isKatherineHunt ||
                        isUhrig ||
                        isLisaMcCollum ||
                        isAnnAlbanese ||
                        isDawnThomas ||
                        listgenUserID === "101567" ||
                        mlsAreaID === "SCL-16N" ||
                        mlsAreaID === "SCL-16A") &&
                      "agent-logo-mr"
                    } ${agentInfoBoxStyles}`}
                  >
                    {isHouy ? (
                      <div>
                        <img
                          className="cover-info-agent-photo-houy"
                          src={photoURL}
                        />
                        <img
                          className={`${slicedLogo} ${
                            isMemberOfTeam && "team-logo"
                          } ${specialLogoStyles}`}
                          src={
                            specialBrokerageLogoURL
                              ? specialBrokerageLogoURL
                              : defaultLogo
                          }
                          alt={specialBrokerageLogoURL}
                        />
                      </div>
                    ) : listgenUserID === "106031" ? null : isDemo ? (
                      <img
                        className={`${demoSlicedLogo} ${
                          isMemberOfTeam && "team-logo"
                        }`}
                        src={shortDemoURL}
                        alt={`${brokerageName} logo`}
                      />
                    ) : specialBrokerageLogoURL ? (
                      <img
                        className={`${slicedLogo} ${
                          isMemberOfTeam && "team-logo"
                        } ${specialLogoStyles}`}
                        src={
                          specialBrokerageLogoURL
                            ? specialBrokerageLogoURL
                            : defaultLogo
                        }
                        alt={specialBrokerageLogoURL}
                      />
                    ) : (
                      ""
                    )}
                    {listgenUserID === "105189" && (
                      <div className="michael-noh-cover-photo-headshot">
                        <img
                          src="https://pma-coverphotos.s3.amazonaws.com/CompassLosAltos_HS_4.20.23.020+Square+Profile_zoomed_white+background.jpg"
                          alt="Michael Noh Headshot"
                        />
                      </div>
                    )}
                  </div>
                </div>
                {listgenUserID === "104626" && (
                  <div className="kristen-wallace-cover-qr-code">
                    <div className="kristen-wallace-qr-container">
                      <img src={homeOwnerURLQRCodePath} alt="QR Code" />
                    </div>

                    <div className="kristen-wallace-cover-text-container">
                      <div className="kristen-wallace-cover-text">
                        SCAN FOR A HOME VALUATION
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

export default QVFourCoverPageInfo;
