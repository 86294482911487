import React, { Component } from "react";
import DBIndicator from "./DBIndicator";
import { propertyUtil } from "views/Pma/PropertyUtil";

class DBBody extends Component {
  calculatePlace = (cityOrArea) => {
    const { hoData, currentArea, demoAccountCity } = this.props;
    const { mlsNeighborhood, mlsCityName, mlsAreaID } = currentArea;
    const { subdivisionName, coverPageHeader } = hoData;
    const { splitAreas } = propertyUtil;

    let areaSplit = splitAreas(mlsNeighborhood);
    let separatedPlace;
    if (
      coverPageHeader &&
      coverPageHeader === "Upper East and Lower Mission Canyon"
    ) {
      separatedPlace = ["Upper East / Lower Mission Canyon"];
    } else if (demoAccountCity && demoAccountCity.length > 1) {
      separatedPlace = [demoAccountCity];
    } else if (subdivisionName && cityOrArea === "area") {
      separatedPlace = [subdivisionName];
    } else if (cityOrArea === "city") {
      separatedPlace = [mlsCityName];
    } else if (cityOrArea === "area") {
      let placeCount = mlsNeighborhood.length;
      if (mlsAreaID === "SBA-20FInv" || mlsAreaID === "SBA-20FRes") {
        separatedPlace = ["Downtown Santa Barbara"];
      } else if (areaSplit.length === 1) {
        separatedPlace = [mlsNeighborhood];
      } else if (areaSplit.length === 2) {
        if (placeCount > 22) {
          separatedPlace = [`${areaSplit[0]} / `, `${areaSplit[1]}`];
        } else {
          separatedPlace = [`${areaSplit[0]} / ${areaSplit[1]}`];
        }
      } else if (areaSplit.length === 3) {
        separatedPlace = [
          `${areaSplit[0]} / ${areaSplit[1]} /`,
          `${areaSplit[2]}`,
        ];
      } else if (areaSplit.length === 4) {
        separatedPlace = [
          `${areaSplit[0]} / ${areaSplit[1]} /`,
          `${areaSplit[2]} / ${areaSplit[3]}`,
        ];
      }
    }
    return separatedPlace;
  };
  createBottomLine = (count, cityOrArea, num) => {
    const {
      hoData,

      sentences,
    } = this.props;
    const { subdivisionName } = hoData;
    let benefitsCount = sentences
      .map((sentence) => sentence.benefit)
      .filter((adv) => adv === "Seller's").length;
    const { endsWithS, slashSeparatedArea } = propertyUtil;
    const { mlsNeighborhood, mlsCityName, mlsAreaID } = this.props.currentArea;
    let place;
    if (subdivisionName && subdivisionName.length > 1) {
      place = subdivisionName;
    } else if (cityOrArea === "city") {
      place = mlsCityName;
    } else if (cityOrArea === "area") {
      if (mlsAreaID === "SBA-20FInv" || mlsAreaID === "SBA-20FRes") {
        place = "Downtown";
      } else if (mlsNeighborhood.length > 43 || mlsAreaID === "SCL-16J") {
        if (benefitsCount === "4") {
          place = "your neighborhood";
        } else {
          place = "your neighborhood's";
        }
      } else {
        if (benefitsCount === "4") {
          place = `the ${slashSeparatedArea(mlsNeighborhood)}`;
        } else {
          place = slashSeparatedArea(mlsNeighborhood);
        }
      }
    }
    let isOrAre = "is";
    if (mlsNeighborhood === "Downtown Condos") {
      isOrAre = "are";
    }

    if (benefitsCount === 4) {
      return `4 out of 4 key Market Trends indicate that ${place} market conditions are favoring ${
        mlsAreaID === "SBA-20FInv" ? "" : "home"
      } sellers.`;
    } else if (benefitsCount === 3) {
      return `3 out of 4 Key Market Trends indicate that ${place} market conditions are favoring ${
        mlsAreaID === "SBA-20FInv" ? "" : "home"
      } sellers.`;
    } else if (benefitsCount === 2) {
      return `2 out of 4 Key Market Trends show that the current market conditions for ${place} ${isOrAre} balanced between Sellers and Buyers.`;
    } else if (benefitsCount === 1) {
      return `1 out of 4 Key Market Trends shows that the current ${place}${endsWithS(
        place
      )} market conditions are shifting away from a seller’s market towards more of a buyer’s market. `;
    } else if (benefitsCount === 0) {
      return `The four market measurements above are not indicating there are any current seller’s advantages –  but these numbers can change quickly.`;
    }
  };
  formatAreaName = () => {
    const { mlsNeighborhood, mlsAreaID } = this.props.currentArea;
    if (mlsAreaID === "SCL-16J") {
      return "Your Neighborhood";
    } else if (mlsAreaID === "SMA-392") {
      return "Isle Cove - Neighborhood 9, Foster City";
    } else if (mlsAreaID === "SMA-398") {
      return "Treasure Isle - Neighborhood 1, Foster City";
    } else if (mlsAreaID === "SMA-390") {
      return "Sea Colony - Neighborhood 7, Foster City";
    } else if (mlsNeighborhood && mlsNeighborhood.length > 28) {
      return "Your Neighborhood";
    } else {
      return mlsNeighborhood;
    }
  };
  getTitleStyles = () => {
    const { mlsNeighborhood, mlsAreaID } = this.props.currentArea;
    if (
      mlsAreaID === "SMA-398" ||
      mlsAreaID === "SMA-390" ||
      mlsAreaID === "SMA-392"
    ) {
      return "medium-header-title";
    }
  };

  render() {
    const { calculatePlace, formatAreaName } = this;
    const {
      sentences,
      actualAdvantagesCount,
      cityOrArea,
      agentData,
      currentArea,
      hasFlippedTeam,
    } = this.props;
    const { mlsAreaID } = currentArea;
    const {
      listgenUserFirstName,

      isMemberOfTeam,
      teamMembers,
      listgenUserID,
    } = agentData;
    let determinePlace = calculatePlace(cityOrArea);
    let maxPlaceCount;
    if (determinePlace.length === 1) {
      maxPlaceCount = Math.max(determinePlace[0].length);
    } else {
      maxPlaceCount = Math.max(
        determinePlace[0].length,
        determinePlace[1].length
      );
    }
    let titleStyles = this.getTitleStyles();

    let signoffText;

    if (isMemberOfTeam) {
      if (hasFlippedTeam) {
        signoffText = `${teamMembers[1].firstName} & ${listgenUserFirstName}`;
      } else if (listgenUserID === "100588") {
        signoffText = "Team Scarborough";
      } else if (teamMembers.length === 2) {
        signoffText = `${listgenUserFirstName} & ${teamMembers[1].firstName}`;
      } else if (teamMembers.length === 3) {
        signoffText = `${listgenUserFirstName}, ${teamMembers[1].firstName}, and ${teamMembers[2].firstName}`;
      }
    } else {
      signoffText = `${listgenUserFirstName}`;
    }
    const { pmaQuarterlyAreaDTO } = this.props.aprilData;

    let timeline;
    let tryArea6Months = pmaQuarterlyAreaDTO.marketTrends.find(
      (x) => x.TrendName === "6-Months Trending"
    );
    let tryAreaPast6Months = pmaQuarterlyAreaDTO.marketTrends.find(
      (x) => x.TrendName === "Past 6-Months Trending"
    );
    if (
      tryArea6Months.AvgSalePrice === "0" ||
      tryAreaPast6Months.AvgSalePrice === "0"
    ) {
      timeline = "1 Year";
    } else {
      timeline = "6 Month";
    }

    return (
      <div className="db-body">
        <div className="db-body-header">
          <p className={`db-body-header-title ${titleStyles}`}>
            {formatAreaName()}
          </p>
        </div>

        <div className="db-indicator-container">
          {sentences.map((sentence, index) => (
            <DBIndicator
              key={sentence.header}
              header={sentence.header}
              logic={sentence.logic}
              statement={sentence.statement}
              benefit={sentence.benefit}
              index={index + 1}
              listgenUserID={listgenUserID}
              mlsAreaID={mlsAreaID}
            />
          ))}
        </div>
        <div className="db-bottom-line">
          <div className="db-bottom-line-text">
            <span className="db-bottom-line-title">Bottom Line:</span>{" "}
            {this.createBottomLine(actualAdvantagesCount, cityOrArea)}{" "}
            <span className="db-signoff">— {signoffText}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default DBBody;
