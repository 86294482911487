import React, { Component } from "react";

import "./customStyles.scss";
import "./customPrintStyles.scss";

class RGB extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,

      coverPageDetails,
    } = this.props;
    console.log("inside rgb", this.props);
    return (
      <div className={`${version}-first-left`}>
        {/*<FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <HOAddress
              hoData={hoData}
              version={version}
              agentData={agentData}
              currentArea={currentArea}
              printVersion={printVersion}
              mailInfoClass="mail-info-container-print"
              typeOfVersion={version}
              coverPageDetails={coverPageDetails}
            />
          )}*/}
        <img
          className="senior-relocation-img"
          src="https://d2onbxnrte2nim.cloudfront.net/102422_SB_SOS_RGB_LogoTesting-01.png"
          alt="RGB SOS"
        />
        <div className="senior-relocation">
          <div className="senior-relocation-title-container">
            <div className="senior-relocation-title-text">
              Are you ready to move, but don't know where to start?
            </div>
          </div>
          <div className="senior-relocation-body">
            <div className="body-title-section">
              <div className="senior-relocation-body-title">
                #1 CONCIERGE SENIOR RELOCATION SERVICE
              </div>
              <div className="senior-relocation-body-subtitle-text">
                Call the SB SOS Team, and let us do all the work.
              </div>
              <div className="senior-relocation-phone-number">
                <div>805</div>
                <div className="phone-dot-separator"></div>
                <div>946</div>
                <div className="phone-dot-separator"></div>
                <div>0060</div>
              </div>
            </div>
            <div className="section-container">
              <div className="section-row">
                <div className="senior-relocation-section">
                  <div className="senior-relocation-section-title">
                    Downsizing
                  </div>
                  <div className="senior-relocation-section-text">
                    <p>We review with you items to keep,</p>
                    <p>gift, sell & donate, then coordinate</p>
                    <p>distribution for you.</p>
                  </div>
                </div>
                <div className="senior-relocation-section">
                  <div className="senior-relocation-section-title">
                    RELOCATION
                  </div>
                  <div className="senior-relocation-section-text">
                    <p>We pack your belongings,</p>
                    <p>coordinate logistics, and set up</p>
                    <p>your new home.</p>
                  </div>
                </div>
              </div>
              <div className="section-row">
                <div className="senior-relocation-section">
                  <div className="senior-relocation-section-title">
                    CLEAN-OUT
                  </div>
                  <div className="senior-relocation-section-text">
                    <p>Upon completion of moving, sales,</p>
                    <p>and donations, we will leave your</p>
                    <p>home real estate ready.</p>
                  </div>
                </div>
                <div className="senior-relocation-section">
                  <div className="senior-relocation-section-title">
                    ESTATE SALES
                  </div>
                  <div className="senior-relocation-section-text">
                    <p>In addition to our local sales</p>
                    <p>effort, our national online</p>
                    <p>auctions favor us to sell to a</p>
                    <p>broader market.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="senior-relocation-logo-container">
            <img
              //src="https://pma-coverphotos.s3.amazonaws.com/SB_SOS_logo_071922.png"
              src="https://d2onbxnrte2nim.cloudfront.net/083122_500px_SB-SOSlogo.png"
              alt="SOS Logo"
              style={{ background: "#bfbdb8", height: "130px" }} //extra yellow color coming in print not PDF so putting background, its not fixed yet, printer issue
            />
          </div>*/}

          <div className="v2-deb-testimonial-qr">
            <div className="qr-container">
              <div className="sbos-link">sbsos.care</div>
            </div>
            <div className="ds-testimonial">
              <div className="ds-testimonial-text-container">
                <div className="ds-testimonial-title">We Love Our Clients</div>
                <div className="ds-testimonial-divider" />
                <div className="ds-testimonial-text">
                  <p>
                    “My wife and I have only owned two homes and we lived for 27
                    years in the home we sold through Deborah. She is
                    professional, competent and, more importantly,
                    client-centered. Deborah always showed she represented us,
                    and was focused on achieving our goals. Deborah and SBSOS
                    made a difficult process less stressful and the timeline to
                    the finish line less difficult.”
                  </p>
                </div>
                <div className="ds-testimonial-homeowner">
                  <p>Andrew & Jo Gifford</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RGB;
// import React, { Component } from "react";
// import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
// import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
// import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
// import "./customStyles.scss";
// import "./customPrintStyles.scss";
// import QVFourTestimonial from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourTestimonial";
// import BeachCliff from "views/Pma/PmaEditor/CustomAgentPages/CustomImages/SB_SOS_Backcover.jpg";
// import SeniorRelocation from "views/Pma/PmaEditor/CustomAgentPages/SeniorRelocation";
// import SOSLogo from "views/Pma/PmaEditor/CustomAgentPages/CustomImages/sb-sos-logo.png";
// import SOSQRCode from "views/Pma/PmaEditor/CustomAgentPages/sos_qr_code.png";
// import PrintV2DebSamuel from "./PrintV2DebSamuel";
//
// class RGB extends Component {
//   render() {
//     const {
//       printVersion,
//       version,
//       agentData,
//       hoData,
//       currentArea,
//       demoAccountHOName,
//       coverPageDetails,
//     } = this.props;
//     console.log("inside rgb", this.props);
//     return (
//       <div className={`${version}-first-left`}>
//         <FirstLeftHeader
//           agentData={agentData}
//           printVersion={printVersion}
//           currentArea={currentArea}
//         />
//         <QVFourLogoAndAvatar agentData={agentData} currentArea={currentArea} />
//         {hoData &&
//           hoData.changeDetails &&
//           Object.keys(hoData.changeDetails).length > 1 && (
//             <HOAddress
//               hoData={hoData}
//               version={version}
//               agentData={agentData}
//               currentArea={currentArea}
//               printVersion={printVersion}
//               mailInfoClass="mail-info-container-print"
//               typeOfVersion={version}
//               coverPageDetails={coverPageDetails}
//             />
//           )}
//         <img
//           className="senior-relocation-img"
//           // src="https://d2onbxnrte2nim.cloudfront.net/030723_DeborahSamual-DS_backPg3.png"
//           src="https://d2onbxnrte2nim.cloudfront.net/102422_SB_SOS_RGB_LogoTesting-01.png"
//         />
//         <div className="senior-relocation">
//           <div className="senior-relocation-title-container">
//             <div className="senior-relocation-title-text">
//               Are you ready to move, but don't know where to start?
//             </div>
//           </div>
//           <div className="senior-relocation-body">
//             <div className="body-title-section">
//               <div className="senior-relocation-body-title">
//                 #1 CONCIERGE SENIOR RELOCATION SERVICE
//               </div>
//               <div className="senior-relocation-body-subtitle-text">
//                 Call the SB SOS Team, and let us do all the work.
//               </div>
//               <div className="senior-relocation-phone-number">
//                 <div>805</div>
//                 <div className="phone-dot-separator"></div>
//                 <div>946</div>
//                 <div className="phone-dot-separator"></div>
//                 <div>0060</div>
//               </div>
//             </div>
//             <div className="section-container">
//               <div className="section-row">
//                 <div className="senior-relocation-section">
//                   <div className="senior-relocation-section-title">
//                     Downsizing
//                   </div>
//                   <div className="senior-relocation-section-text">
//                     <p>We review with you items to keep,</p>
//                     <p>gift, sell & donate, then coordinate</p>
//                     <p>distribution for you.</p>
//                   </div>
//                 </div>
//                 <div className="senior-relocation-section">
//                   <div className="senior-relocation-section-title">
//                     RELOCATION
//                   </div>
//                   <div className="senior-relocation-section-text">
//                     <p>We pack your belongings,</p>
//                     <p>coordinate logistics, and set up</p>
//                     <p>your new home.</p>
//                   </div>
//                 </div>
//               </div>
//               <div className="section-row">
//                 <div className="senior-relocation-section">
//                   <div className="senior-relocation-section-title">
//                     CLEAN-OUT
//                   </div>
//                   <div className="senior-relocation-section-text">
//                     <p>Upon completion of moving, sales,</p>
//                     <p>and donations, we will leave your</p>
//                     <p>home real estate ready.</p>
//                   </div>
//                 </div>
//                 <div className="senior-relocation-section">
//                   <div className="senior-relocation-section-title">
//                     ESTATE SALES
//                   </div>
//                   <div className="senior-relocation-section-text">
//                     <p>In addition to our local sales</p>
//                     <p>effort, our national online</p>
//                     <p>auctions favor us to sell to a</p>
//                     <p>broader market.</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/*<div className="senior-relocation-logo-container">
//             <img
//               //src="https://pma-coverphotos.s3.amazonaws.com/SB_SOS_logo_071922.png"
//               src="https://d2onbxnrte2nim.cloudfront.net/083122_500px_SB-SOSlogo.png"
//               alt="SOS Logo"
//               style={{ background: "#bfbdb8", height: "130px" }} //extra yellow color coming in print not PDF so putting background, its not fixed yet, printer issue
//             />
//           </div>*/}
//
//           <div className="v2-deb-testimonial-qr">
//             <div className="qr-container">
//               <div className="sbos-link">sbsos.care</div>
//             </div>
//             <div className="ds-testimonial">
//               <div className="ds-testimonial-text-container">
//                 <div className="ds-testimonial-title">We Love Our Clients</div>
//                 <div className="ds-testimonial-divider" />
//                 <div className="ds-testimonial-text">
//                   <p>
//                     “My wife and I have only owned two homes and we lived for 27
//                     years in the home we sold through Deborah. She is
//                     professional, competent and, more importantly,
//                     client-centered. Deborah always showed she represented us,
//                     and was focused on achieving our goals. Deborah and SBSOS
//                     made a difficult process less stressful and the timeline to
//                     the finish line less difficult.”
//                   </p>
//                 </div>
//                 <div className="ds-testimonial-homeowner">
//                   <p>Andrew & Jo Gifford</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
//
// export default RGB;
