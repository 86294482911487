import React, { Component } from "react";
import Quarterly from "./Quarterly";
import MidQuarter from "./MidQuarter";
import YearEnd from "./YearEnd";
import YearEnd2022 from "./YearEnd2022";
import TableQuadrants from "./TableQuadrants";
import BubbleCompare from "./BubbleCompare";

import UnderConstruction from "./UnderConstruction";
import { connect } from "react-redux";
import { pmaActions } from "_store/_actions";
import EastBeach from "./EastBeach.jpg";

class PMAEditor extends Component {
  async componentDidMount() {
    // const { printVersion, typeOfMQVersion } = this.props;
    // try {
    //   if (!printVersion && typeOfMQVersion !== "mq3") {
    //     const { listgenUserID } = this.props.agentData;
    //     const { mailerProID } = this.props.singleHOData;
    //     const sat = await this.props.getSAT(mailerProID, listgenUserID);
    //   }
    // } catch {
    //   console.log("error in pma editor");
    // }
  }

  flippedTeam = () => {
    let { agentData } = this.props;

    let cloned2ndTeamMember = agentData.teamMembers[1];
    let updatedFirstMemberInfo = {
      agentDesignation: cloned2ndTeamMember.agentDesignation,
      agentLicenseNumber: cloned2ndTeamMember.agentLicenseNumber,
      emailAddress: cloned2ndTeamMember.email,
      // displayEmailAddress: cloned2ndTeamMember.email,
      listgenUserFirstName: cloned2ndTeamMember.firstName,
      listgenUserLastName: cloned2ndTeamMember.lastName,
      mobilePhoneNo: cloned2ndTeamMember.phone,
    };
    Object.assign(agentData, updatedFirstMemberInfo);
    let teamInfoReversed = agentData.teamMembers.reverse();

    return agentData;
  };

  render() {
    const {
      printVersion,
      version,

      singleHOData,
      marketActivity,
      pma,
      mlsAreas,
      MQCityDetails,

      quarterlyAreaTable,
      quarterlyCityTable,
      navbarData,
      viewingGeneratedCL,
      coverPageDetails,
      agentAdvantages,
      testimonials,
      printTestimonials,
      printAgentAdvantages,
      printAgentDesignations,
      agentDesignations,
      salePriceForCities,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      typeOfVersion,
      flipBook,
      typeOfMQVersion,
      typeOfQVersion,
      typeOfYEVersion,
      pdfPrintVersion,
      dontShowPma,
      showDataVerification,
      missionStatement,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
      volunteerDetails,
      bubbleBoxes,
      quadrantModalOpen,
      oldPhotoDetails,
      photoTableData,
      recentActivityPhotoList,
      photoCheckboxModalOpen,
      isDemo,
      altAgentData,
    } = this.props;

    let { currentArea, isUnavailable } = this.props;
    const { mlsAreaID } = currentArea;

    let { agentData } = this.props;
    const { pmaMarketID } = altAgentData;
    const { listgenUserID, pmaScheduleID } = agentData;
    //console.log("altAgentData", altAgentData);
    // if (pmaScheduleID === "SCHEDULE-3") {
    //   isUnavailable = true;
    // }

    // if (!printVersion) {
    //   isUnavailable = true;
    // }

    // if (
    //   (pmaScheduleID === "SBA1" ||
    //     (pmaScheduleID === "MRY-SCZ-SCL-1" && listgenUserID !== "100190")) &&
    //   !printVersion
    // ) {
    //   isUnavailable = true;
    // }

    // if (
    //   pmaScheduleID === "NOPRINT-SCH" ||
    //   !pmaScheduleID ||
    //   pmaScheduleID == null ||
    //   (pmaScheduleID === "NEW-AGENTS" && !printVersion) ||
    //   pmaScheduleID !== "SBA1" ||
    //   pmaScheduleID !== "MRY-SCZ-SCL-1"
    // ) {
    //   isUnavailable = true;
    // }
    let hasFlippedTeam = false;
    if (
      listgenUserID === "104504" ||
      // listgenUserID === "106463" ||
      listgenUserID === "106397" ||
      listgenUserID === "106028" ||
      listgenUserID === "106999"
    ) {
      this.flippedTeam(agentData);
    }
    if (listgenUserID === "106463") {
      hasFlippedTeam = true;
    }
    if (mlsAreaID === "SCL-15L") {
      currentArea.mlsNeighborhood = "Your Neighborhood";
    }
    if (mlsAreaID === "SCL-1SB") {
      currentArea.mlsNeighborhood = "Borello Ranch Estates & Alicante";
    }
    //console.log("pma editor", this.props);
    if (listgenUserID === "100560") {
      currentArea.mlsNeighborhood = "Coastal Ranch";
    }

    let demoAccountAreaName;
    if (listgenUserID === "100037" && mlsAreaID === "143SalesDemo") {
      demoAccountAreaName = "Montecito Beach";
    } else if (listgenUserID === "100037" && mlsAreaID === "126SalesDemo") {
      demoAccountAreaName = "The Riviera";
    } else if (listgenUserID === "100037" && mlsAreaID === "160SalesDemo") {
      demoAccountAreaName = "East Beach";
    }
    let demoAccountCoverPhoto;
    if (listgenUserID === "100037" && mlsAreaID === "143SalesDemo") {
      demoAccountCoverPhoto = `https://pma-coverphotos.s3.amazonaws.com/Hedgerow_BH_70pct.jpg`;
    } else if (listgenUserID === "100037" && mlsAreaID === "126SalesDemo") {
      demoAccountCoverPhoto = `https://pma-coverphotos.s3.amazonaws.com/RIVIERA_1_40perct.jpg`;
    } else if (listgenUserID === "100037" && mlsAreaID === "160SalesDemo") {
      demoAccountCoverPhoto = EastBeach;
    }

    let demoAccountCity;
    if (listgenUserID === "100037" && mlsAreaID === "143SalesDemo") {
      demoAccountCity = "Montecito";
    } else if (listgenUserID === "100037") {
      demoAccountCity = "Santa Barbara";
    }
    let demoAccountHOName;
    if (listgenUserID === "100037") {
      demoAccountHOName = "Stacey Moore";
    }
    // console.log("****PMA EDITOR", this.props);
    // let isUnavailable = false;
    // if (
    //   mlsAreaID.includes("SCL") &&
    //   listgenUserID !== "101566" &&
    //   listgenUserID !== "101649" &&
    //   listgenUserID !== "101677" &&
    //   listgenUserID !== "101567" &&
    //   !printVersion
    // ) {
    //   isUnavailable = false;
    // }

    let useYETwo = false;
    if (listgenUserID === "100462") {
      useYETwo = true;
    }
    {
      if (
        (isUnavailable && !printVersion) ||
        !agentData ||
        !quarterlyAreaTable ||
        !coverPageDetails
      ) {
        return <UnderConstruction />;
      } else if (dontShowPma) {
        return <UnderConstruction />;
      } else if (version.includes("year-end-2022")) {
        return (
          <YearEnd2022
            pma={pma}
            printVersion={printVersion}
            version={version}
            agentData={agentData}
            hoData={singleHOData}
            currentArea={currentArea}
            navbarData={navbarData}
            coverPageDetails={coverPageDetails}
            yearEnd={yearEnd}
            testimonials={printVersion ? printTestimonials : testimonials}
            demoAccountAreaName={demoAccountAreaName}
            demoAccountCoverPhoto={demoAccountCoverPhoto}
            demoAccountCity={demoAccountCity}
            demoAccountHOName={demoAccountHOName}
            typeOfVersion={typeOfVersion}
            typeOfYEVersion={typeOfYEVersion}
            aprilData={aprilData}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            quadrantDetails={quadrantDetails}
            oldPhotoDetails={oldPhotoDetails}
            photoTableData={photoTableData}
            recentActivityPhotoList={recentActivityPhotoList}
            isDemo={isDemo}
            MQCityDetails={MQCityDetails}
            bubbleBoxes={bubbleBoxes}
            missionStatement={missionStatement}
          />
        );
      } else if (version.includes("bubble-compare")) {
        return (
          <BubbleCompare
            agentData={agentData}
            pma={pma}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            aprilData={aprilData}
            marketActivity={marketActivity}
            printVersion={printVersion}
            version={version}
            hoData={singleHOData}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={printVersion ? printTestimonials : testimonials}
            agentAdvantages={
              printVersion ? printAgentAdvantages : agentAdvantages
            }
            agentDesignations={
              printVersion ? printAgentDesignations : agentDesignations
            }
            quarterlyCityTable={quarterlyCityTable}
            aprilData={aprilData}
            typeOfMQVersion={typeOfMQVersion}
            demoAccountAreaName={demoAccountAreaName}
            demoAccountCoverPhoto={demoAccountCoverPhoto}
            demoAccountCity={demoAccountCity}
            demoAccountHOName={demoAccountHOName}
            missionStatement={missionStatement}
            qArea={qArea}
            qCity={qCity}
            bubbleBoxes={bubbleBoxes}
            isDemo={isDemo}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
          />
        );
      } else if (version.includes("mq")) {
        return (
          <MidQuarter
            agentData={agentData}
            pma={pma}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            aprilData={aprilData}
            marketActivity={marketActivity}
            printVersion={printVersion}
            version={version}
            hoData={singleHOData}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={printVersion ? printTestimonials : testimonials}
            agentAdvantages={
              printVersion ? printAgentAdvantages : agentAdvantages
            }
            agentDesignations={
              printVersion ? printAgentDesignations : agentDesignations
            }
            quarterlyCityTable={quarterlyCityTable}
            aprilData={aprilData}
            typeOfMQVersion={typeOfMQVersion}
            demoAccountAreaName={demoAccountAreaName}
            demoAccountCoverPhoto={demoAccountCoverPhoto}
            demoAccountCity={demoAccountCity}
            demoAccountHOName={demoAccountHOName}
            missionStatement={missionStatement}
            qArea={qArea}
            qCity={qCity}
          />
        );
      } else if (version.includes("table-quadrants")) {
        return (
          <TableQuadrants
            agentData={agentData}
            hoData={singleHOData}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            marketActivity={marketActivity}
            navbarData={navbarData}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            viewingGeneratedCL={viewingGeneratedCL}
            coverPageDetails={coverPageDetails}
            agentAdvantages={agentAdvantages}
            testimonials={printVersion ? printTestimonials : testimonials}
            agentDesignations={agentDesignations}
            salePriceForCities={salePriceForCities}
            yearEnd={yearEnd}
            qArea={qArea}
            qCity={qCity}
            quadrantModalOpen={quadrantModalOpen}
            aprilData={aprilData}
            typeOfVersion={typeOfVersion}
            flipBook={flipBook}
            typeOfQVersion={typeOfQVersion}
            showDataVerification={showDataVerification}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quadrantDetails={quadrantDetails}
          />
        );
      } else if (version.includes("ye2")) {
        return (
          <YearEnd
            agentData={agentData}
            hoData={singleHOData}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            marketActivity={marketActivity}
            navbarData={navbarData}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            viewingGeneratedCL={viewingGeneratedCL}
            coverPageDetails={coverPageDetails}
            agentAdvantages={agentAdvantages}
            testimonials={printVersion ? printTestimonials : testimonials}
            agentDesignations={agentDesignations}
            salePriceForCities={salePriceForCities}
            yearEnd={yearEnd}
            qArea={qArea}
            qCity={qCity}
            quadrantModalOpen={quadrantModalOpen}
            aprilData={aprilData}
            typeOfVersion={typeOfVersion}
            flipBook={flipBook}
            typeOfQVersion={typeOfQVersion}
            showDataVerification={showDataVerification}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quadrantDetails={quadrantDetails}
          />
        );
      } else if (version.includes("q")) {
        return (
          <Quarterly
            agentData={agentData}
            altAgentData={altAgentData}
            hoData={singleHOData}
            currentArea={currentArea}
            MQCityDetails={MQCityDetails}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            marketActivity={marketActivity}
            navbarData={navbarData}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            viewingGeneratedCL={viewingGeneratedCL}
            coverPageDetails={coverPageDetails}
            agentAdvantages={agentAdvantages}
            testimonials={printVersion ? printTestimonials : testimonials}
            agentDesignations={agentDesignations}
            salePriceForCities={salePriceForCities}
            yearEnd={yearEnd}
            qArea={qArea}
            qCity={qCity}
            bubbleBoxes={bubbleBoxes}
            quadrantModalOpen={quadrantModalOpen}
            aprilData={aprilData}
            typeOfVersion={typeOfVersion}
            flipBook={flipBook}
            typeOfQVersion={typeOfQVersion}
            showDataVerification={showDataVerification}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quadrantDetails={quadrantDetails}
            volunteerDetails={volunteerDetails}
            recentActivityPhotoList={recentActivityPhotoList}
            photoCheckboxModalOpen={photoCheckboxModalOpen}
            missionStatement={missionStatement}
            isDemo={isDemo}
            hasFlippedTeam={hasFlippedTeam}
          />
        );
      } else {
        return <UnderConstruction />;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    testimonials: state.pma.testimonials,
    agentDesignations: state.pma.agentDesignations,
    agentAdvantages: state.pma.agentAdvantages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSAT: (hoID, listgenUserID) => {
      dispatch(pmaActions.getSAT(hoID, listgenUserID));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PMAEditor);
