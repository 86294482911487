import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import MQThreeAHGraph from "./MQThreeAHGraph";

class MQThreeAvailableHomes extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  }
  render() {
    const {
      currentArea,
      quarterlyAreaTable,
      MQCityDetails,
      hoData,
      agentData,
      demoAccountAreaName,
      demoAccountCity,
      quarterlyCityTable,
      useCity,
      altVersion,
      printVersion,
    } = this.props;

    const { mlsAreaID, mlsNeighborhood, mlsCityName } = currentArea;
    const { subdivisionName, coverPageHeader } = hoData;
    const { listgenUserID } = agentData;
    const {
      homesNumber,
      slashSeparatedArea,
      splitAreas,
      getSubdivisionName,
    } = propertyUtil;

    let areaTitle = useCity
      ? quarterlyCityTable.combinedCitymonthsSupplyTable.CityName
      : listgenUserID === "102935"
      ? "Our Neighborhood"
      : coverPageHeader === "Upper East and Lower Mission Canyon"
      ? "Upper East/Lower Mission Canyon"
      : coverPageHeader
      ? coverPageHeader
      : getSubdivisionName(subdivisionName, mlsNeighborhood);
    if (mlsAreaID === "SMA-390") {
      areaTitle = "Sea Colony Neighborhood";
    }
    let twoAreas = true;
    if (mlsAreaID === "157" || mlsAreaID === "1522155") {
      twoAreas = false;
    }
    if (mlsCityName && quarterlyAreaTable.monthsSupplyTable) {
      let city12;
      let cityName;

      let citySale;
      let maxCity;

      if (quarterlyCityTable && useCity) {
        const { monthsSupplyTable } = quarterlyCityTable;

        city12 = monthsSupplyTable.AvgHomesSoldLast12Months;
        cityName = monthsSupplyTable.CityName;

        citySale = monthsSupplyTable.CityNumberOfHousesForSale;
        maxCity = monthsSupplyTable.MaxHousesSoldLast12Months;
      } else {
        city12 = MQCityDetails.avgHomesSoldLast12Months;
        cityName = MQCityDetails.cityName;

        citySale = MQCityDetails.numberOfHomesForSale;
        maxCity = MQCityDetails.maxHousesSoldLast12Months;
      }
      let areaSale;

      if (useCity) {
        areaSale =
          quarterlyCityTable.combinedCitymonthsSupplyTable
            .CityNumberOfHousesForSale;
        // areaTitle = getSubdivisionName(
        //   subdivisionName,
        //   quarterlyCityTable.CityName
        // );
      } else {
        areaSale =
          quarterlyAreaTable.monthsSupplyTable.AreaNumberOfHousesForSale;
      }
      let {
        AvgHomesSoldLast12Months: area12,
      } = quarterlyAreaTable.monthsSupplyTable;
      const avgCity = Number(city12);
      let cityScale = [];

      for (let i = 0; i <= 10; i++) {
        if (avgCity === 0) {
          cityScale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
          break;
        } else if (i === 0) {
          cityScale.push(0);
        } else {
          cityScale.push(cityScale[cityScale.length - 1] + avgCity);
        }
      }
      let mappedCityBar = cityScale.map((tick) => (
        <p key={tick.toString()}>{tick}</p>
      ));

      const avgArea = Number(area12);

      let areaScale = [];

      for (let i = 0; i <= 10; i++) {
        if (avgArea === 0) {
          areaScale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
          break;
        } else if (i === 0) {
          areaScale.push(0);
        } else {
          areaScale.push(areaScale[areaScale.length - 1] + avgArea);
        }
      }

      let mappedAreaBar = areaScale.map((tick) => (
        <p key={tick.toString()}>{tick}</p>
      ));

      const areaPx = Math.min(
        100,
        homesNumber(
          areaSale,
          avgArea * 10,
          false,
          false,
          maxCity,
          citySale,
          avgCity,
          avgArea
        )
      );

      const areaBg = homesNumber(
        areaSale,
        avgArea * 10,
        true,
        false,
        maxCity,
        citySale,
        avgCity,
        avgArea
      )[0];

      const areaInventory = homesNumber(
        areaSale,
        avgArea * 10,
        true,
        false,
        maxCity,
        citySale,
        avgCity,
        avgArea
      )[1];

      const cityPx = Math.min(
        100,
        homesNumber(
          citySale,
          avgCity * 10,
          false,
          true,
          maxCity,
          citySale,
          avgCity,
          avgArea
        )
      );
      const cityBg = homesNumber(
        citySale,
        avgCity * 10,
        true,
        true,
        maxCity,
        citySale,
        avgCity,
        avgArea
      )[0];
      const cityInventory = homesNumber(
        citySale,
        avgCity * 10,
        true,
        true,
        maxCity,
        citySale,
        avgCity,
        avgArea
      )[1];

      let cityPerc;
      if (cityPx === 0 || cityPx === 1) {
        cityPerc = 2;
      } else if (cityPx > 0 && citySale < 2) {
        cityPerc = Math.round(
          (citySale / cityScale[cityScale.length - 1]) * 100
        );
      } else {
        cityPerc = cityPx;
      }

      let areaPerc;
      if (areaPx === 0 || areaPx === 1) {
        areaPerc = 1;
      } else if (areaPx > 0 && areaSale < 2) {
        areaPerc = Math.round(
          (areaSale / areaScale[areaScale.length - 1]) * 100
        );
      } else {
        areaPerc = areaPx;
      }

      const cityOverflowDiff = citySale - cityScale.slice(-1);
      const areaOverflowDiff = areaSale - areaScale.slice(-1);

      let allAreas;
      let areaSplit = splitAreas(mlsNeighborhood);
      const areaCount = areaSplit.length;
      allAreas = slashSeparatedArea(mlsNeighborhood);

      let availableHomesPositionStyles;

      let isGailPearl = listgenUserID === "103455";
      if (isGailPearl) {
        availableHomesPositionStyles = "higher-available-homes-position";
      }
      return (
        <div
          className={`${printVersion ? "print-" : "editor-"}${
            altVersion ? altVersion : "mq3"
          }-available-homes-container ${availableHomesPositionStyles}`}
        >
          <div className="available-homes-inner">
            <div
              className={"ah-bar-container " + (twoAreas ? "" : "two-areas")}
            >
              {twoAreas && (
                <MQThreeAHGraph
                  isCity={true}
                  areaCityName={cityName}
                  bg={cityBg}
                  ddPerc={cityPerc}
                  inventory={cityInventory}
                  sale={citySale}
                  overflowDiff={cityOverflowDiff}
                  mappedBar={mappedCityBar}
                  demoAccountCity={demoAccountCity}
                  currentArea={currentArea}
                />
              )}

              <MQThreeAHGraph
                isCity={false}
                areaCount={areaCount}
                areaSplit={areaSplit}
                demoAccountAreaName={demoAccountAreaName}
                // areaCityName={allAreas}
                areaCityName={areaTitle}
                bg={areaBg}
                ddPerc={areaPerc}
                inventory={areaInventory}
                sale={areaSale}
                overflowDiff={areaOverflowDiff}
                mappedBar={mappedAreaBar}
                twoAreas={twoAreas}
                useCity={useCity}
                currentArea={currentArea}
              />
            </div>
            <div className="inventory-bars-container">
              <div className="inventory-bar-item low-inventory-bar">
                <div className="sold inventory-legend" />
                <div className="bar-item-row">
                  <span>Low Inventory</span>
                  <span className="bar-item-divider"></span>
                  <span>&lt; 4 month's supply</span>
                </div>
              </div>

              <div className="inventory-bar-item">
                <div className="active inventory-legend" />
                <div className="bar-item-row">
                  <span>Balanced Inventory</span>
                  <span className="bar-item-divider"></span>
                  <span>4-6 month's supply</span>
                </div>
              </div>

              <div className="inventory-bar-item">
                <div className="primary inventory-legend" />
                <div className="bar-item-row">
                  <span>High Inventory</span>
                  <span className="bar-item-divider"></span>
                  <span>&gt; 6 month's supply</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <p>Loading..</p>;
    }
  }
}

export default MQThreeAvailableHomes;
