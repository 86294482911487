import React, { Component } from "react";
import FirstLeftHeader from "./FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "./QVFourLogoAndAvatar";

class TopAgentInfoAndAddress extends Component {
  render() {
    const {
      agentData,
      currentArea,
      printVersion,
      isDemo,
      hoData,
      version,
      coverPageDetails,
      quadrantModalOpen,
      mlsAreaID,
    } = this.props;
    return (
      <div className={`${quadrantModalOpen && "first-left-dim"}`}>
        <FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          mlsAreaID={mlsAreaID}
          currentArea={currentArea}
          isDemo={isDemo}
          printVersion={printVersion}
        />
        <HOAddress
          hoData={hoData}
          version={version}
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
          mailInfoClass="qv4-mail-info-container-print"
          versionType="qv4"
          typeOfVersion="qv4"
          coverPageDetails={coverPageDetails}
        />
      </div>
    );
  }
}

export default TopAgentInfoAndAddress;
