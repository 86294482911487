import React, { Component } from "react";

import MQThreeTestimonial2 from "./MQThreeTestimonial2";
import MQThreeAgentAndBrokerageAdvantages from "./MQThreeAgentAndBrokerageAdvantages";
import QVThreeDonutBar from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/QVThreeDonutBar";
import QVThreeAvgPriceBarChart from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/QVThreeAvgPriceBarChart";
import BackToSchool from "views/Pma/PmaEditor/CustomAgentPages/BackToSchool";

class MQThreeFirstLeft extends Component {
  render() {
    const {
      auth,
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      testimonials,
      agentAdvantages,
      agentDesignations,
      demoAccountHOName,
      coverPageDetails,
      MQCityDetails,
      quarterlyAreaTable,
      missionStatement,
    } = this.props;
    const { mlsAreaID } = currentArea;
    const { listgenUserID } = agentData;
    //console.log("first left", this.props);
    let useCity = false;
    // if (mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G") {
    //   useCity = true;
    // }
    //console.log("useCity", useCity);
    return (
      <div className="mq3-first-left">
        {/*<FirstLeftHeader
          auth={auth}
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          printVersion={printVersion}
        />
        <HOAddress
          hoData={hoData}
          version={version}
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
          mailInfoClass="mail-info-container-print"
          version={version}
          demoAccountHOName={demoAccountHOName}
          coverPageDetails={coverPageDetails}
        />*/}
        {listgenUserID === "100988" ? (
          <BackToSchool />
        ) : (
          <>
            {(testimonials || missionStatement) && (
              <MQThreeTestimonial2
                agentData={agentData}
                missionStatement={missionStatement}
              />
            )}

            <div className="neighborhood-trends-title-container">
              <div className="neighborhood-trends-title">
                Neighborhood Trends
              </div>

              <div className="neighborhood-trends-title-divider"></div>
            </div>

            <QVThreeAvgPriceBarChart
              hoData={hoData}
              currentArea={currentArea}
              MQCityDetails={MQCityDetails}
            />
            <QVThreeDonutBar
              useCity={useCity}
              MQCityDetails={MQCityDetails}
              quarterlyAreaTable={useCity ? MQCityDetails : quarterlyAreaTable}
            />
          </>
        )}
      </div>
    );
  }
}

export default MQThreeFirstLeft;
